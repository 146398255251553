//************************************************************************************************
//es6 modules
//************************************************************************************************

import React, { useEffect, useState } from 'react';
import { ActivityIndicator, FlatList, Button, Image, StyleSheet, Text, View } from 'react-native';
import ReactDOM from 'react-dom';
import ReactDataGrid from 'react-data-grid';
import { Toolbar, Data, Filters } from "react-data-grid-addons";
import faker from "faker";
import PropTypes from 'prop-types';
import axios from "axios";
import { CSVLink, CSVDownload } from "react-csv";
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import "../assets/custom_styles.css";
import globalVars from "./globalVars.js"
import loading from '../assets/loading.gif'


//************************************************************************************************
// Javascript Start
//************************************************************************************************
 
//************************//
// Global Variables Start //
//************************//

var StartDateMS = (Date.now() - 2*604800000)/1000;
var StopDateMS = Date.now()/1000

var StartDate = new Date(StartDateMS).toISOString().split('T')[0]
var StopDate = new Date(StopDateMS).toISOString().split('T')[0]

var filteredRows
var transactionCount
var amountTotal
var tbl = []

//***********************//
// Global Variables Stop //
//***********************//

const selectors = Data.Selectors;

//***************//
// Filters Start //
//***************//

const {
	NumericFilter,
	AutoCompleteFilter,
	MultiSelectFilter,
	SingleSelectFilter
} = Filters;

//**************//
// Filters Stop //
//**************//

//*************************//
// Column Properties Start //
//*************************//

const defaultColumnProperties = {
	filterable: true,
	width: 250
};

//************************//
// Column Properties Stop //
//************************//

//*********************//
// Row Renderers Start //
//*********************//


//********************//
// Row Renderers Stop //
//********************//

//***********************//
// Cell Formatters Start //
//***********************//

const amountFormatter = ({ value }) => {
	return <div>${value}</div>;
};

//**********************//
// Cell Formatters Stop //
//**********************//
/*
const columns = [
	{ key: 'date',		 			name: 'Date'	},
	{ key: 'status',	 			name: 'Status'	},
	{ key: 'type',		 			name: 'Type'	},
	{ key: 'amount',	 			name: 'Amount',					filterRenderer: NumericFilter, formatter: amountFormatter },	
	{ key: 'location', 				name: 'Subdepartment Name' },		
	{ key: 'site',		 			name: 'Subdepartment Number',	filterRenderer: NumericFilter },
	{ key: 'purchaseOrderNumber',	name: 'PO #'	},	
	{ key: 'guid',		 			name: 'Load ID'	}
*/
const columns = [
	{ key: 'txnID',				name: 'GUID' 					},
	{ key: 'creationTime',		name: 'Creation Time' 			},
	{ key: 'amount',			name: 'Amount', 				filterRenderer: NumericFilter, formatter: amountFormatter },
	{ key: 'truckID',			name: 'Truck Id' 				},
	{ key: 'carrier',			name: 'Carrier' 				},
	{ key: 'date',				name: 'Date' 					},
	{ key: 'subDept',			name: 'Subdepartment Number' 	},
	{ key: 'driverPhone',		name: 'Driver Phone Number', 	filterRenderer: NumericFilter },
	{ key: 'po_numbers',		name: 'PO Number' 				},
	{ key: 'subDeptName',		name: 'Subdepartment Name' 		},
	{ key: 'vendor',			name: 'Vendor' 					},
	{ key: 'door',				name: 'Door' 					},
	{ key: 'status',			name: 'Status' 					},
	{ key: 'payType',		name: 'Payment Type' 			},
	{ key: 'payHost',		name: 'Payment Host' 			},
	{ key: 'authNumber',		name: 'Auth Number' 			},
	{ key: 'controlNumber',		name: 'Control Number' 			},
	{ key: 'odometer',			name: 'Odometer' 				},
	{ key: 'wpToken',			name: 'WP Token' 				},
	{ key: 'paymentMedia',		name: 'Payment Media' 			},
	{ key: 'expressCode',		name: 'Express Code' 			},
	{ key: 'hostRequest',		name: 'Host Request' 			},
	{ key: 'hostResponse',		name: 'Host Response' 			}

].map(c => ({ ...c, ...defaultColumnProperties }));

const handleFilterChange = filter => filters => {
	const newFilters = { ...filters };
	if (filter.filterTerm) {
		newFilters[filter.column.key] = filter;
	} else {
		delete newFilters[filter.column.key];
	}
	return newFilters;
};

function getValidFilterValues(rows, columnId) {
	console.log(rows)
	return rows
		.map(r => r[columnId])
		.filter((item, i, a) => {
			return i === a.indexOf(item);
		});
}

function getRows(rows, filters) {
	return selectors.getRows({ rows, filters });
}

function createRow(r) {
	return {
		txnID: 			r.txnID,
		creationTime: 	r.creationTime,
		amount: 		r.amount/100,
		truckID: 		r.truckID,
		carrier: 		r.carrier,
		date: 			r.date,
		subDept: 		r.subDept,
		driverPhone: 	r.driverPhone,
		po_numbers: 	r.po_numbers,
		subDeptName: 	r.subDeptName,
		vendor: 		r.vendor,
		door: 			r.door,
		status: 		r.status,
		payType: 	r.payType,
		payHost: 	r.payHost,
		authNumber: 	r.authNumber,
		controlNumber: 	r.controlNumber,
		odometer: 		r.odometer,
		wpToken: 		r.wpToken,
		paymentMedia: 	r.paymentMedia,
		expressCode: 	r.expressCode,
		hostRequest: 	r.hostRequest,
		hostResponse: 	r.hostResponse
	};
}
function min(a,b) { 
	if(a < b) { 
		return a
	} else { 
		return b
	}
}

function formatTable(t) { 
	var ft = []

	for (let i = 0; i < t.length; i++) { 
		ft.push(createRow(t[i]))
	}
	return ft
}

//************************************************
// Axios Stuff Start
//************************************************

//*****************\\
// Display On Load \\
//*****************\\

function DisplayFiscalDataOnLoad() {

	console.log("IP:",globalVars.OldAuthProvider)

	const [rows, setRows] = useState([]);
	const [value, onChange] = useState(new Date());
	const [filters, setFilters] = useState({});
	const [n, setN] = useState(0);

	if(tbl.length == 0 ) { 
		console.log('GET Request');
		const res = axios({
			method: 'get', 
			url: globalVars.PaymentGateway + '/transactions?start_date=' + Math.round(StartDateMS) + '&end_date=' + Math.round(StopDateMS)
		},{
			headers: {
				"Authorization": "Basic dGVzdDE6c29tZXRoaW5nIGhhc2hlZCB0aGlzIHdheSBjb21lcw=="
			}
		})
		.then(res => {
			console.log("res:",res)
			tbl = res.data
			console.log("res.data:",res.data)
			setRows(formatTable(tbl))
		})
		.catch(err => console.error(err));			
	} 

	filteredRows = getRows(rows, filters);
/*
	if(filters === {}) { 
		transactionCount = rows.length
	} else { 
		amountTotal = 0
		transactionCount = filteredRows.length
		if(filteredRows[0] !== undefined) { 
			for (let i = 0; i < filteredRows.length; i++) { 
					amountTotal += filteredRows[i].amount
			}
		}
	}
*/
	const calChange = e => { 
		onChange(e)
		StartDate = e[0].toISOString().split('T')[0];
		StopDate = (e[1]).toISOString().split('T')[0];
		StartDateMS = Date.parse(StartDate)/1000
		StopDateMS = Date.parse(StopDate)/1000
//		setRows(formatTable(tbl,StartDateMS,StopDateMS)) 
	}

	const increment = e => { 

	}	

	const decrement = e => { 

	}	

	const changeStart = e => {
		StartDate = e.target.value
		StartDateMS = Date.parse(StartDate)
		if( isNaN(StartDateMS)) { 
			StartDateMS = Date.now() - 2*604800000;
		}
//		setRows(formatTable(tbl,StartDateMS,StopDateMS))
	};

	const changeStop = e => {
		StopDate = e.target.value
		StopDateMS = Date.parse(StopDate)
		if(isNaN(StopDateMS)) { 
			StopDateMS = Date.now()
		}
//		setRows(formatTable(tbl,StartDateMS,StopDateMS))
	};

	if(tbl.length == 0 ) {
		return ( 	
			<View>
				<View>
					<Calendar 
						onChange={calChange}
						value={value}
						selectRange={true}
					/>
				</View>	
				<View style={styles.image}>
					<img src={loading} alt="loading..."/>
				</View>
			</View>
		)

	} else { 
		return (
			<View>
				<Text>
					Transaction Count: {transactionCount}
					Transaction Count / 100: {transactionCount/100}
				</Text>
				<View>
					<Calendar 
						onChange={calChange}
						value={value}
						selectRange={true}
					/>
				</View>

				<View style={styles.container}>
					<form>
						<label>From Date:</label>
						<input type="text" name='FromDate' onChange={changeStart}/>
						<label>To Date:</label>
						<input type="text" name='ToDate' onChange={changeStop}/>
					</form>
				</View>

				<View>
					<form>
						<input type="button" name='decrement' onClick={decrement}/>
						<input type="button" name='increment' onClick={increment}/>
					</form> 
					<div className="custom-style">
						<ReactDataGrid
							columns={columns}
							rowGetter={i => filteredRows[i]}
							rowsCount={100}
							width={250}
							toolbar={<Toolbar enableFilter={true} />}
							onAddFilter={filter => setFilters(handleFilterChange(filter))}
							onClearFilters={() => setFilters({})}
							getValidFilterValues={columnKey => getValidFilterValues(rows, columnKey)}
							enableCellSelect={true}
						/>
					</div>
				</View>
			</View>
		);
	}


}

//************************************************
// Axios Stuff Stop
//************************************************

//************************************************************************************************
// Javascript Stop
//************************************************************************************************

//************************************************************************************************
// Main Start
//************************************************************************************************

export default ({history}) => (

	<View>
		<View > <h3>Fiscal Data From {StartDate} to {StopDate} </h3> </View>
		<Button title="Fiscal Report" onPress={() => {
			resetData()
			history.push("/pages/FiscalReport")}
		}></Button>
		<Button title="Host Transaction Report" onPress={() => {
			resetData()	 
			history.push("/pages/BankReport")}
		}></Button>
		<Button title="Host Deposit Report" onPress={() => {
			resetData()
			history.push("/pages/BankDepositReport")}
		}></Button>
		<Button title="Gap Report"	onPress={() => {
			resetData()
			history.push("/pages/GapReport")}
		}></Button>
		<Button title="Gap Deposit Report"	onPress={() => {
			resetData()
			history.push("/pages/GapDepositReport")}
		}></Button>
		<Button title="Support" onPress={() => {
			resetData()
			history.push("/pages/SupportFiscalReport")}
		}></Button>
		<View> {DisplayFiscalDataOnLoad()} </View>


	</View>

);

/*
		<View>Transaction Total: {transactionCount}</View>
		<View>Amount Total: ${amountTotal.toFixed(2)}</View>
		<CSVLink data={filteredRows}>Download Fiscal Data</CSVLink>
*/

//************************************************************************************************
// Main Stop
//************************************************************************************************

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#fff',
		alignItems: 'strech',
		justifyContent: 'center',
		padding: 20
	},
	image: { 
		flex: 1,
		width:500,
		justifyContent: 'center',
	}
});

function resetData() { 
	StartDateMS = Math.round((Date.now() - 2*604800000)/1000);
	StopDateMS = Math.round(Date.now())
	StartDate = new Date(StartDateMS).toISOString().split('T')[0]
	StopDate = new Date(StopDateMS).toISOString().split('T')[0]
	tbl = [];
}