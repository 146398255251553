import React from 'react';
import { StyleSheet, View } from 'react-native';
import { NativeRouter, Switch, Route } from 'react-router-native';
import Index from "./pages/Index"
import GapReport from "./pages/GapReport"
import BankReport from "./pages/BankReport"
import BankDepositReport from "./pages/BankDepositReport"
import FiscalReport from "./pages/FiscalReport"
import SupportFiscalReport from "./pages/SupportFiscalReport"
import GapDepositReport from "./pages/GapDepositReport"
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './Configs/authConfig'
import { PublicClientApplication } from '@azure/msal-browser';

const msalInstance = new PublicClientApplication(msalConfig);

export default function App() {
  return (
    <MsalProvider instance = {msalInstance}>
        <NativeRouter>
          <View style={styles.container}>
            <Switch>
              <Route exact path="/" component={Index} />
              <Route exact path="/pages/GapReport" component={GapReport} />
              <Route exact path="/pages/BankReport" component={BankReport} />
              <Route exact path="/pages/BankDepositReport" component={BankDepositReport} />
              <Route exact path="/pages/GapDepositReport" component={GapDepositReport} />
              <Route exact path="/pages/FiscalReport" component={FiscalReport} />
              <Route exact path="/pages/SupportFiscalReport">
                <SupportFiscalReport darkMode={false}></SupportFiscalReport>
              </Route>
              <Route exact path="/pages/SupportFiscalReportDarkMode">
                <SupportFiscalReport darkMode={true}></SupportFiscalReport>
              </Route>
            </Switch>
          </View>
        </NativeRouter>
      </MsalProvider>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'strech',
    justifyContent: 'center',
    padding: 20
  },
});
