import { LogLevel } from '@azure/msal-browser';
import Constants from 'expo-constants';

export const msalConfig = {

    auth: {
        clientId: Constants.manifest.extra.ClientId,
        authority: Constants.manifest.extra.Authority,
        knownAuthorities: [Constants.manifest.extra.KnownAuthorities],
        redirectUri: Constants.manifest.extra.RedirectUri,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {	
                    return;	
                }
                switch (level) {	
                    case LogLevel.Error:	
                        console.error(message);	
                        return;	
                    case LogLevel.Info:	
                        console.info(message);	
                        return;	
                    case LogLevel.Verbose:	
                        console.debug(message);	
                        return;	
                    case LogLevel.Warning:	
                        console.warn(message);	
                        return;	
                    default:
                        console.log(message);
                        return;
                }
            }
        }
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: Constants.manifest.extra.Scopes.split(',').map(s => s.trim()),
};

export const editProfile = {
    authority: process.env.REACT_APP_ADB2C_EDIT_PROFILE_AUTHORITY,
};

export const policyNames = {
    signUpSignIn: process.env.REACT_APP_ADB2C_SIGNUP_SIGNIN_POLICY
};
