//************************************************************************************************
//es6 modules
//************************************************************************************************

import React from 'react';
import {Button, StyleSheet, Text, View, TextInput } from 'react-native';
import ReactDataGrid from 'react-data-grid';
import { Toolbar, Data, Filters, Menu } from "react-data-grid-addons";
import axios from "axios";
import { CSVLink } from "react-csv";
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import globalVars from "./globalVars.js";
import { TextareaAutosize } from '@material-ui/core';
import loading from '../assets/loading.gif'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import CSVReader from 'react-csv-reader'
import Cookies from 'universal-cookie';
import { MsalContext } from "@azure/msal-react";
import { loginRequest } from '../Configs/authConfig.js';

const {
	DraggableHeader: { DraggableContainer }
} = require("react-data-grid-addons");

const { ContextMenu, MenuItem, SubMenu, ContextMenuTrigger } = Menu;

var StartDateMS = Math.round((Date.now() - 1 * 86400000));
var StopDateMS = Math.round(Date.now())

var StartDate = new Date(StartDateMS).toISOString().split('T')[0]
var StopDate = new Date(StopDateMS).toISOString().split('T')[0]


var tbl = []
var checkNumberList = []


const selectors = Data.Selectors;

const options = [
	{ value: -1, label: 'No Host', pn: 'None' },
	{ value: 1, label: 'COMDATA_CHECK', pn: '800-741-3030' },
	{ value: 2, label: 'COMDATA_CARD', pn: '800-741-3030' },
	{ value: 4, label: 'EFS_CHECK', pn: '866 841 9315' },
	{ value: 8, label: 'EFS_CARD', pn: '866 841 9315' },
	{ value: 16, label: 'TCH_CHECK', pn: '866 841 9315' },
	{ value: 32, label: 'TCH_CARD', pn: '866 841 9315' },
	{ value: 64, label: 'TCHECK_CARD', pn: '866 661 2435' },
	{ value: 128, label: 'TCHECK_CHECK', pn: '866 661 2435' },
	{ value: 256, label: 'WP_VISA', pn: '800 859 5965' },
	{ value: 512, label: 'WP_MASTERCARD', pn: '800 859 5965' },
	{ value: 1024, label: 'WP_DISCOVER', pn: '800 859 5965' },
	{ value: 2048, label: 'WP_AMEX', pn: '800 859 5965' },
	{ value: 4096, label: 'RELAY', pn: 'None' },
]

const statusEnum = [
	{ value: 1, label: 'CREATED' },
	{ value: 2, label: 'DECLINED' },
	{ value: 4, label: 'CANCELED' },
	{ value: 8, label: 'AUTHORIZED' },
	{ value: 16, label: 'PRE_AUTHORIZED' },
	{ value: 32, label: 'PRE_EDITED' },
	{ value: 64, label: 'PROMPTING_USER' },
	{ value: 128, label: 'MANUALLY_AUTHORIZED' },
	{ value: 256, label: 'USERCANCELED' },
]

const hostEnum = [
	{ value: 1, label: 'HOST_COMDATA' },
	{ value: 2, label: 'HOST_TCH' },
	{ value: 4, label: 'HOST_EFS' },
	{ value: 8, label: 'HOST_TCHECK' },
	{ value: 16, label: 'HOST_WORLDPAY' },
	{ value: 32, label: 'HOST_RELAY' },
]

const {
	NumericFilter,
	AutoCompleteFilter,
	MultiSelectFilter,
	SingleSelectFilter
} = Filters;

const defaultColumnProperties = {
	filterable: true,
	sortable: true,
	draggable: true,
	editable: true
};

const payTypeFormatter = (value) => {
	var x = "No Valid Payment Method";
	for (var i = 0; i < options.length; i++) {
		if (value == options[i].value) {
			x = options[i].label
		}
	}

	return x;
};

const statusFormatter = (value) => {
	var x = "No Valid Status Found";
	for (var i = 0; i < statusEnum.length; i++) {
		if (value == statusEnum[i].value) {
			x = statusEnum[i].label
		}
	}

	return x;
};

const hostFormatter = (value) => {
	var x = "No Valid Host Found";
	for (var i = 0; i < hostEnum.length; i++) {
		if (value == hostEnum[i].value) {
			x = hostEnum[i].label
		}
	}

	return x;
};

const amountFormatter = ({ value }) => {
	return <div>${value}</div>;
};

//**********************//
// Cell Formatters Stop //
//**********************//

let columns = [
	{ key: 'txnID', name: 'Transaction ID', width: 150, },
	{ key: 'creationTime', name: 'Creation Time', width: 550, },
	{ key: 'amount', name: 'Amount', width: 150, formatter: amountFormatter },
	{ key: 'truckID', name: 'Truck Id', width: 550, },
	{ key: 'carrier', name: 'Carrier', width: 350, },
	{ key: 'date', name: 'Date', width: 150, },
	{ key: 'subDept', name: 'Subdept #', width: 150, },
	{ key: 'driverPhone', name: 'Driver Phone #', width: 150, filterRenderer: NumericFilter },
	{ key: 'po_numbers', name: 'PO Number', width: 250, },
	{ key: 'subDeptName', name: 'Subdept Name', width: 150, },
	{ key: 'vendor', name: 'Vendor', width: 550, },
	{ key: 'door', name: 'Door', width: 150, },
	{ key: 'status', name: 'Status', width: 300, },
	{ key: 'payType', name: 'Payment Type', width: 250, },
	{ key: 'payHost', name: 'Payment Host', width: 250, },
	{ key: 'authNumber', name: 'Auth Number', width: 150, },
	//	{ key: 'controlNumber',	name: 'Control Number' 			},
	{ key: 'odometer', name: 'Odometer', width: 150, },
	{ key: 'wpToken', name: 'WP Token', width: 250, },
	{ key: 'paymentMedia', name: 'Payment Media', width: 250, },
	{ key: 'expressCode', name: 'Express Code', width: 250, },
	//	{ key: 'hostRequest',	name: 'Host Request' 			},
	//	{ key: 'hostResponse',	name: 'Host Response' 			}
].map(c => ({ ...c, ...defaultColumnProperties }));


function getValidFilterValues(rows, columnId) {
	return rows
		.map(r => r[columnId])
		.filter((item, i, a) => {
			return i === a.indexOf(item);
		});
}

function getRows(rows, filters) {
	return selectors.getRows({ rows, filters });
}


function createRow(r) {
	var d = new Date(r.creationTime)
	return {
		creationTime: d.toString(),
		txnID: r.txnID,
		amount: r.amount / 100,
		truckID: r.truckID,
		carrier: r.carrier,
		date: r.date,
		subDept: r.subDept,
		driverPhone: r.driverPhone,
		po_numbers: r.po_numbers.join(" | "),
		subDeptName: r.subDeptName,
		vendor: r.vendor,
		door: r.door,
		status: statusFormatter(r.status),
		statusCode: r.status,
		payType: payTypeFormatter(r.payType),
		payTypeCode: r.payType,
		payHost: hostFormatter(r.payHost),
		payHostCode: r.payHost,
		authNumber: r.authNumber,
		controlNumber: r.controlNumber,
		odometer: r.odometer,
		wpToken: r.wpToken,
		paymentMedia: r.paymentMedia,
		expressCode: r.expressCode,
		hostRequest: r.hostRequest.replace(/[\r\n]/gm, ''),
		hostResponse: r.hostResponse.replace(/[\r\n]/gm, '')
	};
}

function min(a, b) {
	if (a < b) {
		return a
	} else {
		return b
	}
}

function formatTable(t) {
	var ft = []

	for (let i = 0; i < t.length; i++) {
		ft.push(createRow(t[i]))
	}
	return ft
}

class SupportPage extends React.Component {
	static contextType = MsalContext;
	constructor(props) {
		const cookies = new Cookies();

		var col = cookies.get('columns')
		if (col) {
			col = col.map(c => ({ ...c, ...defaultColumnProperties }));
		}


		super(props);

		this.state = {
			//			rows: [], 
			retryStatus: "", //Display text to the user about the resubmission of the transaction to ale
			manualAuthStatus: "", //Display text to the user about the about the status of the manual auth
			value: new Date(), //Calander Date or group of days
			filters: {}, //Applay filters to the columns and sort the data in the table according to the filters
			authCode: "", //Code for the manual auth.
			hostCode: -1, //Used for a manual transaction. No valid host code means no manual auth. 
			alert: "", //Displays text to indicate the status of the processing of the checknumbers. 
			support_data: ["None", "No Data", "No Data", "No Data"], //support_data[0] is the txn_id | support_data[1] is hostRequest | support_data[2] is hostResponse | support_data[3] is not used for anything
			currentOption: options[0], // Payment type and phone numbers. 
			currentColumn: col || columns, // Main column object used in the react data grid.
			//			currentColumn: columns,
			currentRow: [], // Row clicked on by the user. 
			filteredRows: [], // Formatted and filtered collection of rows. 
			text: '', // Doesn't do anything.
			show: false, // Reveals the confirmation text during the manual auth.
			darkMode: false, //Doesn't do anything
			c: cookies, // Formatted Column information.
			transactionId: '',
			ponumber:'',
			drivernumber: '',
			amount:'',
			subdept:'',
			start: StartDateMS,
			end: StopDateMS,
			isLoading: false,
			currentPage:1,
			totaldatacount:0,
		};

		this.setAuthNumber = this.setAuthNumber.bind(this);
		this.orderColumn = this.orderColumn.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.columnRemove = this.columnRemove.bind(this);
		this.columnAdd = this.columnAdd.bind(this);
		this.onHeaderDrop = this.onHeaderDrop.bind(this);

		this.onCloseAlert = this.onCloseAlert.bind(this);
		this.onShowAlert = this.onShowAlert.bind(this);

		//		this.refreshTrans(); 

	}


	ExampleContextMenu1 = ({
		idx,
		id,
		rowIdx,
		hideCol,

	}) => {

		return (
			<ContextMenu id={id}>
				<MenuItem data={{ rowIdx, idx }} onClick={hideCol}>
					Remove Column {idx}
				</MenuItem>
				<SubMenu title="Add Column">
					{
						this.state.currentColumn.findIndex(x => x.key === "txnID") == -1 &&
						<MenuItem onClick={this.columnAdd.bind(this, 0)}>
							Transaction ID
						</MenuItem>
					}
					{
						this.state.currentColumn.findIndex(x => x.key === "creationTime") == -1 &&
						<MenuItem onClick={this.columnAdd.bind(this, 1)}>
							Creation Time
						</MenuItem>
					}
					{
						this.state.currentColumn.findIndex(x => x.key === "amount") == -1 &&
						<MenuItem onClick={this.columnAdd.bind(this, 2)}>
							Amount
						</MenuItem>
					}
					{
						this.state.currentColumn.findIndex(x => x.key === "truckID") == -1 &&
						<MenuItem onClick={this.columnAdd.bind(this, 3)}>
							Truck Id
						</MenuItem>
					}
					{
						this.state.currentColumn.findIndex(x => x.key === "carrier") == -1 &&
						<MenuItem onClick={this.columnAdd.bind(this, 4)}>
							Carrier
						</MenuItem>
					}
					{
						this.state.currentColumn.findIndex(x => x.key === "date") == -1 &&
						<MenuItem onClick={this.columnAdd.bind(this, 5)}>
							Date
						</MenuItem>
					}
					{
						this.state.currentColumn.findIndex(x => x.key === "subDept") == -1 &&
						<MenuItem onClick={this.columnAdd.bind(this, 6)}>
							Subdepartment #
						</MenuItem>
					}
					{
						this.state.currentColumn.findIndex(x => x.key === "driverPhone") == -1 &&
						<MenuItem onClick={this.columnAdd.bind(this, 7)}>
							Driver Phone #
						</MenuItem>
					}
					{
						this.state.currentColumn.findIndex(x => x.key === "po_numbers") == -1 &&
						<MenuItem onClick={this.columnAdd.bind(this, 8)}>
							PO #
						</MenuItem>
					}
					{
						this.state.currentColumn.findIndex(x => x.key === "subDeptName") == -1 &&
						<MenuItem onClick={this.columnAdd.bind(this, 9)}>
							Subdepartment Name
						</MenuItem>
					}
					{
						this.state.currentColumn.findIndex(x => x.key === "vendor") == -1 &&
						<MenuItem onClick={this.columnAdd.bind(this, 10)}>
							Vendor
						</MenuItem>
					}
					{
						this.state.currentColumn.findIndex(x => x.key === "door") == -1 &&
						<MenuItem onClick={this.columnAdd.bind(this, 11)}>
							Door
						</MenuItem>
					}
					{
						this.state.currentColumn.findIndex(x => x.key === "status") == -1 &&
						<MenuItem onClick={this.columnAdd.bind(this, 12)}>
							Status
						</MenuItem>
					}
					{
						this.state.currentColumn.findIndex(x => x.key === "payType") == -1 &&
						<MenuItem onClick={this.columnAdd.bind(this, 13)}>
							Payment Type
						</MenuItem>
					}
					{
						this.state.currentColumn.findIndex(x => x.key === "payHost") == -1 &&
						<MenuItem onClick={this.columnAdd.bind(this, 14)}>
							Payment Host
						</MenuItem>
					}
					{
						this.state.currentColumn.findIndex(x => x.key === "authNumber") == -1 &&
						<MenuItem onClick={this.columnAdd.bind(this, 15)}>
							Auth #
						</MenuItem>
					}
					{
						this.state.currentColumn.findIndex(x => x.key === "odometer") == -1 &&
						<MenuItem onClick={this.columnAdd.bind(this, 16)}>
							Odometer
						</MenuItem>
					}
					{
						this.state.currentColumn.findIndex(x => x.key === "wpToken") == -1 &&
						<MenuItem onClick={this.columnAdd.bind(this, 17)}>
							WP Token
						</MenuItem>
					}
					{
						this.state.currentColumn.findIndex(x => x.key === "paymentMedia") == -1 &&
						<MenuItem onClick={this.columnAdd.bind(this, 18)}>
							Payment Media
						</MenuItem>
					}
					{
						this.state.currentColumn.findIndex(x => x.key === "expressCode") == -1 &&
						<MenuItem onClick={this.columnAdd.bind(this, 19)}>
							Express Code
						</MenuItem>
					}
				</SubMenu>
			</ContextMenu>
		);
	}

	sortRows = (initialRows, sortColumn, sortDirection) => {

		const comparer = (a, b, sortColumn, sortDirection) => {
			if (sortDirection === "ASC") {
				return a[sortColumn] > b[sortColumn] ? 1 : -1;
			} else if (sortDirection === "DESC") {
				return a[sortColumn] < b[sortColumn] ? 1 : -1;
			}
		};

		return [...initialRows].sort((a, b) => { return comparer(a, b, sortColumn, sortDirection) });

	};

	componentDidMount() {
		console.log('componentDidMount');
		console.log("STATE DARK THEME", this.state.darkTheme)
		const instance = this.context.instance;
		const accounts = this.context.accounts;
		const inProgress = this.context.inProgress;
		const request = {
			...loginRequest,
			account: accounts[0]
		};

		if (instance && accounts[0] && inProgress == "none") {
			instance.acquireTokenSilent(request).then((response) => {
				if (tbl.length == 0) {

					axios.defaults.headers.common['Authorization'] = `Bearer ${response.accessToken}`
					axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
				}
			}).catch((e) => {
				console.log("Error trying to get token:", e);
			});

		}

	}
	paginate(e) {
		 this.setState({ currentPage: e },this.getTransactions);  
	}
	 


	previousPage(e, y) {
		 
		if (e !== Math.ceil(y / 100)) {
			 if(e==0){
				this.setState({ currentPage: e },this.getTransactions);
			 }else{
				this.setState({ currentPage: e - 1 },this.getTransactions);
			 }
			 
			 
		}
		
	}
	nextPage(e, y) {
		 
		if (e  !== Math.ceil(y / 100)) {
			this.setState({ currentPage: e + 1 },this.getTransactions);
		}
	}
	unwatchTransaction = e => {

		console.log('unwatchTransaction');

		const res = axios({
			method: 'post',
			url: globalVars.PaymentGateway + '/payments/unwatch?txn_id=' + this.state.support_data[0]
		})
			.then(res => {
				console.log("res", res)
			}).catch(err => {
				console.error(err)
			});

	}

	refreshTrans = e => {

		this.setState(state => ({
			retryStatus: "",
			manualAuthStatus: ""
		}));

		console.log('refreshTrans');
		this.getTransactions()


	}
	transactionIdChange = e => {
		const value =  e.target.value;
		this.setState( state => ({
			transactionId:value
		}))
	}
	ponumberChange = e => {
		
		const value = e.target.value;  
		this.setState(state => ({
			ponumber: value
		}))
	}
	drivernumberChange = e => {
		const value = e.target.value;
		this.setState(state => ({
			drivernumber: value
		}))
	}
	subdeptChange = e => {
		const value = e.target.value;
		this.setState(state => ({
			subdept: value
		}))
	}
	amountChange = e => {
		const value = e.target.value;
		this.setState(state => ({
			amount: value
		}))
	}
	
	getTransactions = () => {
		this.setState(state => ({
			isLoading: true
		}))
		let transactionId = parseInt(this.state.transactionId);
		 
		 
		let confirmed = false;
		console.log("TransactoionId",transactionId);

		// if(isNaN(transactionId) || transactionId < 1){
		// 	confirmed = confirm("Are you sure you want to search without Transaction Number, it could be very slow.");
		// 	if(!confirmed) {
		// 		this.setState(state => ({
		// 			isLoading: false
		// 		}))
		// 		return;
		// 	}
		// }
		 
		
		axios({
			method: 'get',
			url: globalVars.PaymentGateway + 
			'/transactions?start_date=' + Math.round(this.state.start) + 
			'&end_date=' + Math.round(this.state.end) + 
				'&transaction_id=' + ((isNaN(this.state.transactionId) || this.state.transactionId == '') ? 0 : this.state.transactionId) +
				'&po_number=' + (this.state.ponumber == ''  ? '' : this.state.ponumber) +
				'&driver_number=' + ( this.state.drivernumber == '' ? '' : this.state.drivernumber) +
				'&sub_dept=' + ((isNaN(this.state.subdept) || this.state.subdept == '') ? 0 : this.state.subdept) +
				'&amount=' + ((isNaN(this.state.amount) || this.state.amount == '') ? 0 : this.state.amount) +
			'&pagenumber=' + this.state.currentPage
		})
			.then(res => {
				tbl = res.data.data
				this.setState(state => ({
					filteredRows: getRows(formatTable(tbl), this.state.filters)
				}));
				this.setState(state => ({
					totaldatacount: res.data.totalcount
				}));
				
			})
			.catch((error) => {
				console.log("err");
				
				 
			})
			.finally(() => {this.setState(state => ({
				isLoading: false
			}))})

	}
	calChange = e => {
		this.setState(state => ({
			value: e,
			retryStatus: "",
			manualAuthStatus: ""
		}));
		StartDate = e[0].toISOString().split('T')[0];
		StopDate = (e[1]).toISOString().split('T')[0];
		this.setState({
			start: Date.parse(StartDate),
			end: Date.parse(StopDate)
			}, 
			this.getTransactions
		) 
		console.log('GET Request');
		
	}

	setAuthNumber(e) {
		console.log("Data", e.target.value)
		this.setState({ authCode: e.target.value });
	}

	setpayType = e => {
		var t = options.findIndex(x => x.value === e.value);
		console.log("Payment Type: ", e.value);
		this.setState(state => ({
			hostCode: e.value,
			currentOption: options[t]
		}));
	}

	cancel = e => {
		this.setState(state => ({
			show: false,
		}));
	}

	manualAuth = e => {

		e.preventDefault();

		this.setState(state => ({
			retryStatus: "",
			manualAuthStatus: "Pending...",
			show: false,
		}));

		console.log(this.state.currentRow.payType)

		if (this.state.currentRow.status == "CREATED" ||
			this.state.currentRow.status == "DECLINED" ||
			this.state.currentRow.status == "MANUALLY_AUTHORIZED") {
			if (this.state.support_data[0] != "" &&
				this.state.support_data[0] != "None" &&
				this.state.support_data[0] != null &&
				this.state.authCode != "" &&
				this.state.hostCode != -1 &&
				typeof this.state.hostCode != 'undefined' &&
				this.state.currentRow.payType != "No Valid Payment Method" &&
				typeof this.state.currentRow.payType != 'undefined') {

				const res1 = axios({
					method: 'post',
					url: globalVars.PaymentGateway + 
					'/payments/manual_auth?txn_id=' + this.state.support_data[0] + 
					'&manual_auth_code=' + this.state.authCode + 
					'&payment_type=' + this.state.currentRow.payTypeCode
				}, {
					headers: {
						"Authorization": "Basic dGVzdDE6c29tZXRoaW5nIGhhc2hlZCB0aGlzIHdheSBjb21lcw=="
					}
				})
					.then(res1 => {
						if (res1.status == 200) {
							this.setState(state => ({
								manualAuthStatus: "Complete!"
							}));
						}

						console.log(res1.data)

						var updatedRow = createRow(res1.data);

						var currentRowsCopy = this.state.filteredRows;

						var t = currentRowsCopy.findIndex(x => x.txnID === updatedRow.txnID);

						currentRowsCopy[t] = updatedRow;

						this.setState(state => ({
							filteredRows: currentRowsCopy
						}));

					})
					.catch(err => {
						console.error(err)
						this.setState(state => ({
							manualAuthStatus: "Failed! Server Error."
						}));
					});

			} else {

				console.log("You need a transaction which has a load id, you need to provide an authCode, and you need a host.")
				this.setState(state => ({
					manualAuthStatus: "Failed! Load id, payment host, payment type, or Manual Auth Code not found."
				}));

			}

		} else {

			console.log("You need a transaction which is either Created or Declined.")
			this.setState(state => ({
				manualAuthStatus: "Failed! Transaction must be Created, Declined, or Manually Authorized."
			}));

		}



	}

	retry_tool = e => {
		e.preventDefault();

		this.setState(state => ({
			retryStatus: "Pending...",
			manualAuthStatus: ""
		}));

		console.log("Retry:")
		const res1 = axios({
			method: 'post',
			url: globalVars.PaymentGateway + '/payments/retry_auth?txn_id=' + this.state.support_data[0]
		})
			.then(res1 => {
				console.log("Retry Res:", res1)
				if (res1.status == 200) {
					this.setState(state => ({
						retryStatus: "Complete"
					}));
				}
			})
			.catch(err => {
				console.error(err)
				this.setState(state => ({
					retryStatus: "Failed!"
				}));
			});

	}

	logRow = t => {

		this.setState(state => ({
			retryStatus: "",
			manualAuthStatus: "",
			show: false
		}));

		if (t === undefined) {
			this.setState(state => ({
				support_data: ["None", "None", "None"]
			}));

		} else {

			this.setState(state => ({
				currentRow: t
			}));

			if (t.hostRequest !== undefined && t.hostResponse !== undefined) {
				console.log("Row has ftrans.")
				console.log(t.hostRequest, t.hostResponse)
				this.setState(state => ({
					support_data: [t.txnID, t.hostRequest, t.hostResponse]
				}));
				//				setSupportData([t.txnID,t.hostRequest,t.hostResponse])
			} else if (t.hostRequest !== undefined && t.hostResponse === undefined) {
				console.log("No data from the host.")
				this.setState(state => ({
					support_data: [t.txnID, t.hostRequest, "No data from the host."]
				}));
				//				setSupportData([t.txnID,t.hostRequest,"No data from the host."])
			} else if (t.hostRequest === undefined && t.hostResponse === undefined) {
				console.log("No data sent to the host and no data from the host.")
				this.setState(state => ({
					support_data: [t.txnID, "No data sent to the host", "No data from the host."]
				}));
				//				setSupportData([t.txnID,"No data sent to the host","No data from the host."])
			} else if (t.hostRequest === undefined && t.hostResponse !== undefined) {
				console.log("Weird error condition.")
				this.setState(state => ({
					support_data: [t.txnID, "No data sent to the host", "Error, Data from the host and not sent to the host:" + t.hostResponse]
				}));
				//				setSupportData([t.txnID,"No data sent to the host","Error, Data from the host and not sent to the host:" + t.hostResponse])
			}

			console.log("ID", t.payHost)
			this.setState(state => ({
				currentOption: t.payHost,
				hostCode: t.payHostCode,
			}));

			//			setOption(options[0])
			//			setHostCode(-1)			
			for (var i = 0; i < options.length; i++) {
				if (t.payHost === undefined) {
					console.log("Payment host is undefined:")
					this.setState(state => ({
						currentOption: options[0],
						hostCode: -1
					}));
					//					setOption(options[0])
					//					setHostCode(-1)
					break;
				} else {
					if (t.payType == options[i].label) {
						console.log("Label:", options[i].label)
						this.setState(state => ({
							currentOption: options[i],
							hostCode: t.payHostCode,
						}));
						//						setOption(options[i])
						//						setHostCode(t.payHost)
						break;
					}
				}
			}
		}
	}

	onHeaderDrop = (source, target) => {

		this.setState(state => ({
			retryStatus: "",
			manualAuthStatus: ""
		}));

		console.log(source, target)

		const stateCopy = Object.assign({}, this.state);
		const columnSourceIndex = this.state.currentColumn.findIndex(
			i => i.key === source
		);

		const columnTargetIndex = this.state.currentColumn.findIndex(
			i => i.key === target
		);

		stateCopy.currentColumn.splice(
			columnTargetIndex,
			0,
			stateCopy.currentColumn.splice(columnSourceIndex, 1)[0]
		);

		const emptyColumns = Object.assign({}, this.state, { currentColumn: [] });
		this.setState(emptyColumns);

		const reorderedColumns = Object.assign({}, this.state, {
			currentColumn: stateCopy.currentColumn
		});
		this.setState(reorderedColumns);

		const jsonCopy = JSON.stringify(stateCopy.currentColumn)

		this.state.c.set('columns', jsonCopy, { path: '/' });

		this.setState(state => ({
			filters: {},
			filteredRows: getRows(formatTable(tbl), {})
		}));
	};

	ProcessCheckNumbers = data => {
		var error = 0;
		console.log(data, data.length)
		if (data.length > 0 && data.length <= 3000) {
			for (var i = 0; i < data.length; i++) {
				var y = i + 1;
				if (data[i].length != 1) {
					error = 1;
					this.setState(state => ({
						alert: "Error on line " + y + ". Each line must have 1 entry."
					}));
					//					setAlert("Error on line " + y + ". Each line must have 1 entry.")					
					checkNumberList = [];
					break;
				}
				for (var j = 0; j < checkNumberList.length; j++) {
					var x = j + 1
					if (data[i][0] == checkNumberList[j]) {
						error = 1;
						this.setState(state => ({
							alert: "Line: " + y + " is a duplicate of line: " + x
						}));
						//						setAlert("Line: " + y + " is a duplicate of line: " + x)					
						checkNumberList = [];
						break;
					}
				}

				if (isNaN(data[i][0])) {
					error = 1;
					this.setState(state => ({
						alert: "Line: " + y + " is not a number."
					}));
					//					setAlert("Line: " + y + " is not a number.")					
					checkNumberList = [];
					break;
				}

				if (error == 1) {
					break;
				}

				checkNumberList.push(data[i][0])

			}

			if (error == 0) {
				console.log(checkNumberList)
				const json = JSON.stringify(checkNumberList);
				const res = axios.post(globalVars.PaymentGateway + '/checkNumber_addCheckArray', json, {
					headers: {
						// Overwrite Axios's automatically set Content-Type
						'Content-Type': 'application/json'
					}
				}).then(res => {
					if (res.data[0] != "n") {
						var dupStr = "Upload Error duplicate entry: "
						for (var i = 0; i < res.data.length; i++) {
							dupStr += res.data[i] + " "
						}
						this.setState(state => ({
							alert: dupStr
						}));
						//						setAlert(dupStr)
						console.log(res.data[0])
					} else {
						this.setState(state => ({
							alert: "Upload Complete."
						}));
						//						setAlert("Upload Complete.")
						checkNumberList = [];
					}
				})
					.catch(err => console.error(err));
			}
			error = 0;
		} else {
			if (data.length > 3000) {
				this.setState(state => ({
					alert: "Error, File is to long."
				}));
				//				setAlert("Error, File is to long.")
			} else {
				this.setState(state => ({
					alert: "Error, File doesn't contain any information."
				}));
				//				setAlert("Error, File doesn't contain any information.")
			}
		}
	}

	orderColumn = (filteredRows, sortColumn, sortDirection) => {

		console.log(sortColumn, sortDirection)

		var x = this.sortRows(filteredRows, sortColumn, sortDirection)

		console.log(x)
		/*
				this.setState(state => ({
					rows: x
				}));
		*/
		this.setState(state => ({
			filteredRows: getRows(x, this.state.filters),
			retryStatus: "",
			manualAuthStatus: ""
		}));

	}

	sortFunction(a, b) {
		if (a.txnID === b.txnID) {
			return 0;
		}
		else {
			return (a.txnID > b.txnID) ? -1 : 1;
		}
	}


	handleFilterChange = filter => {

		const newFilters = { ...this.state.filters };
		if (filter.filterTerm) {
			newFilters[filter.column.key] = filter;
		} else {
			delete newFilters[filter.column.key];
		}

		this.setState(state => ({
			filters: newFilters,
			retryStatus: "",
			manualAuthStatus: ""
		}));

		return newFilters;
	};

	columnRemove = (idx) => {

		let x = this.state.currentColumn.slice(idx + 1, this.state.currentColumn.length);
		let y;
		let z;

		if (idx != 0) {
			y = this.state.currentColumn.slice(0, idx);

			z = y.concat(x)
		} else {
			z = x;
		}

		const jsonCopy = JSON.stringify(z)

		this.state.c.set('columns', jsonCopy, { path: '/' });

		this.setState(state => ({
			currentColumn: z,
			filters: {},
			filteredRows: getRows(formatTable(tbl), {}),
			retryStatus: "",
			manualAuthStatus: ""
		}));

	}

	columnAdd = (idx) => {

		let columnCopy = columns.slice(idx, idx + 1);

		var dup = this.state.currentColumn.findIndex(x => x.key === columnCopy[0].key);

		let z;

		if (dup == -1) {
			z = columnCopy.concat(this.state.currentColumn)

			const jsonCopy = JSON.stringify(z)

			this.state.c.set('columns', jsonCopy, { path: '/' });

			this.setState(state => ({
				currentColumn: z,
				retryStatus: "",
				manualAuthStatus: ""
			}));
		}

	}

	onCloseAlert = () => {
		this.setState(state => ({
			text: '',
			show: false
		}));
	}

	onShowAlert = e => {
		e.preventDefault();
		this.setState(state => ({
			show: true
		}));
	}

	onSetPhoneNumber = e => {

	}

	onSetEmailAddress = e => {

	}

	onSubmitEmail = e => {

	}

	onSubmitText = e => {

	}
	msalLogout = () => {
		const msalInstance = this.context.instance;
		msalInstance.logoutRedirect({
			postLogoutRedirectUri: "/",
		})
	}

	render() {

		require(this.props.darkMode ? "../assets/custom_styles_darkMode.css" : "../assets/custom_styles.css")

		if (tbl.length == 0) {
			return (

				<View>
					<Button color={this.props.darkMode ? "#000000" : undefined}  title="Logout" onPress={this.msalLogout}></Button>
					<View>

						<div className="col-12">
						<br />
							<label htmlFor="transactionNumber" className="form-label"> Transaction ID</label>
							<input type="number" className="form-control" id="transactionNumber" placeholder="Transaction ID"
						onChange={this.transactionIdChange}
						value={this.state.transactionId}
							/>
							 

							<label htmlFor="ponumber" className="form-label">Enter Po Number</label>
							<input type="text" className="form-control" id="ponumber" placeholder="PO Number"
								onChange={this.ponumberChange}
								value={this.state.ponumber}
							/>

							 

							<label htmlFor="drivernumber" className="form-label">Enter Driver Number</label>
							<input type="number" className="form-control" id="drivernumber" placeholder="Driver Number"
								onChange={this.drivernumberChange}
								value={this.state.drivernumber}
							/>

							 

							<br /><br />
							<label htmlFor="Subdept" className="form-label">Enter Subdept/Site</label>
							<input type="number" className="form-control" id="subdept" placeholder="subdept/Site"
								onChange={this.subdeptChange}
								value={this.state.subdept}
							/>

						 
							<label htmlFor="Amount" className="form-label">Enter Amount</label>
							<input type="number" className="form-control" id="amount" placeholder="amount"
								onChange={this.amountChange}
								value={this.state.amount}
							/>

							<br /><br />
						</div>
						
						<Button color={this.props.darkMode ? "#000000" : undefined}  title="Get Transactions" onPress={() => this.getTransactions(0)}></Button>

					</View>
					<View>
						<Calendar
							onChange={this.calChange}
							value={this.state.value}
							selectRange={true}
						/>
					</View>
					{this.state.isLoading && <View style={styles.image}>
						<img src={loading} alt="loading..." />
					</View>}
				</View>
			)

		} else {

			//			filteredRows = getRows(this.state.rows, this.state.filters);
			const pageNumbers = [];

			for (let i = 1; i <= Math.ceil(this.state.totaldatacount / 500); i++) {
				pageNumbers.push(i);
			}

			return (
				<View >
					<Button color={this.props.darkMode ? "#000000" : undefined}  title="Logout" onPress={this.msalLogout}></Button>
					<View>
					<div className="col-12">
					<br />
					<label htmlFor="transactionNumber" className="form-label">Transaction ID</label>
							<input type="number" className="form-control" id="transactionNumber" placeholder="Transaction ID"
					onChange={this.transactionIdChange}
					value={this.state.transactionId}
							/>
							<label htmlFor="ponumber" className="form-label">Enter Po Number</label>
							<input type="text" className="form-control" id="ponumber" placeholder="PO Number"
								onChange={this.ponumberChange}
								value={this.state.ponumber}
							/>



							<label htmlFor="drivernumber" className="form-label">Enter Driver Number</label>
							<input type="number" className="form-control" id="drivernumber" placeholder="Driver Number"
								onChange={this.drivernumberChange}
								value={this.state.drivernumber}
							/>



							<br /><br />
							<label htmlFor="Subdept" className="form-label">Enter Subdept/Site</label>
							<input type="number" className="form-control" id="subdept" placeholder="subdept/Site"
								onChange={this.subdeptChange}
								value={this.state.subdept}
							/>


							<label htmlFor="Amount" className="form-label">Enter Amount</label>
							<input type="number" className="form-control" id="amount" placeholder="amount"
								onChange={this.amountChange}
								value={this.state.amount}
							/>

							<br /><br />


					</div>
					<br />
					<Button color={this.props.darkMode ? "#000000" : undefined}  title="Get Transactions" onPress={() => this.getTransactions(0)}></Button>


					</View>
					<View>
						<Calendar
							onChange={this.calChange}
							value={this.state.value}
							selectRange={true}
						/>
					</View>
					<View>

						<div className="custom-style">
							<DraggableContainer onHeaderDrop={this.onHeaderDrop}>
								<ReactDataGrid

									columns={this.state.currentColumn}
									rowGetter={i => this.state.filteredRows[i]}
									rowsCount={this.state.filteredRows.length}

									toolbar={<Toolbar enableFilter={true} />}
									onAddFilter={filter => {
										//setFilters(handleFilterChange(filter))
										var x = this.handleFilterChange(filter)
										this.setState(state => ({
											filteredRows: getRows(formatTable(tbl), x)
										}));
										return x;
									}
									}
									onClearFilters={() => {
										//setFilters({})
										this.setState(state => ({
											filters: {},
											filteredRows: getRows(this.state.filteredRows, {})
										}));
									}
									}
									getValidFilterValues={columnKey => getValidFilterValues(this.state.filteredRows, columnKey)}
									enableCellSelect={true}
									onRowClick={i => this.logRow(this.state.filteredRows[i])}
									onGridSort={(sortColumn, sortDirection) => {
										this.setState(state => ({
											filteredRows: this.orderColumn(this.state.filteredRows, sortColumn, sortDirection)
										}))
									}
									}

									contextMenu={
										<this.ExampleContextMenu1
											hideCol={(e, { rowIdx, idx }) => this.columnRemove(idx)}
										/>
									}

									onColumnResize={(idx, width) =>
										console.log(`Column ${idx} has been resized to ${width}`)
									}
									//   enableDragAndDrop={true}
									RowsContainer={ContextMenuTrigger}


								/>


							</DraggableContainer>
							
							<div className="pagination-container">
								<ul className="pagination">
								 
									{pageNumbers.map((number) => (
										<li
											key={number}
											onClick={() => this.paginate(number)}
											className="page-number"
										>
											{number}
										</li>
									))}  
									 
								</ul>
							</div>
						</div>
						<br />
					</View>
					<View>
						<div style={{ border: 10, borderStyle: 'solid' }}>
							<form onSubmit={this.onShowAlert}>
								Manual Auth Number:
								<input type="text" name='Manual_Auth' value={this.state.authCode} onChange={this.setAuthNumber} />
								<Dropdown disabled options={options} onChange={this.setpayType} value={this.state.currentOption} placeholder="Select an option" />
								<Text>Phone Number: {this.state.currentOption.pn} </Text> <br />
								<input type="submit" value="Submit" />
							</form>
							{
								this.state.show &&
								<form onSubmit={this.manualAuth}>
									Are you sure you want to manually auth {this.state.currentRow.txnID}? <br />
									<input type="submit" value="Yes!" />
								</form>
							}
							{this.state.manualAuthStatus}
						</div>
						<div style={{ border: 10, borderStyle: 'solid' }}>
							<Button color={this.props.darkMode ? "#000000" : undefined}  title="Resubmit Transaction to ALE." onPress={this.retry_tool}> </Button>
							{this.state.retryStatus}
						</div>
						<div style={{ border: 10, borderStyle: 'solid' }}>
							<Button color={this.props.darkMode ? "#000000" : undefined}  title="Refresh Table" onPress={this.refreshTrans}></Button>
						</div>
						{
							this.state.support_data[0] != "None" && this.state.support_data[0] != "" &&
							<div style={{ border: 10, borderStyle: 'solid', margin: 'auto' }}>
								<a href={globalVars.CapPayV2Link + "?load_id=" + this.state.support_data[0]} >Capstone Pay Link</a>
							</div>
						}
						{
							this.state.support_data[0] != "None" && this.state.support_data[0] != "" &&
							<div style={{ border: 10, borderStyle: 'solid', margin: 'auto' }}>
								<Button color={this.props.darkMode ? "#000000" : undefined}  title="Unwatch transaction" onPress={this.unwatchTransaction}></Button>
							</div>
						}

						<div style={{ border: 10, borderStyle: 'solid' }}>
							<Text>Host Request:</Text> <br />
							<TextareaAutosize value={this.state.support_data[1]} style={{ width: "99%" }} /> <br />
							<Text>Host Response:</Text> <br />
							<TextareaAutosize value={this.state.support_data[2]} style={{ width: "99%" }} />
						</div>
						<div style={{ border: 10, borderStyle: 'solid' }}>
							<Text>Check Number Upload:</Text> <br />
							<CSVReader onFileLoaded={(data, fileInfo) => this.ProcessCheckNumbers(data)} />
							<div>{this.state.alert}</div>
						</div>
					</View>
					<CSVLink data={this.state.filteredRows}>Download Data</CSVLink>
				</View>
			);
		}
	}


}

export default ({ history, darkMode }) => {
	return(
	
	<View style={darkMode ? darkTheme.container : lightTheme.container}>
		<Text> <h3>Fiscal Data</h3> </Text>
		<SupportPage darkMode={darkMode}/>
	</View>

)};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#fff',
		alignItems: 'strech',
		justifyContent: 'center',
		padding: 20
	},
	input: {
		height: 40,
		margin: 12,
		borderWidth: 1,
		padding: 10,
	  },
});

const lightTheme = {
	container: {
		flex: 1,
		alignItems: 'strech',
		justifyContent: 'center',
		padding: 20,
		body: '#FFFFFF',
		text: '#363537',
		toggleBorder: '#FFF',
		background: '#FFFFFF',
	}
}

const darkTheme = {
	container: {
		flex: 1,
		alignItems: 'strech',
		justifyContent: 'center',
		padding: 20,
		body: '#363537',
		text: '#FAFAFA',

		toggleBorder: '#6B8096',
		background: '#999',
	}
}

function resetData() {

	StartDateMS = Math.round((Date.now() - 1 * 604800000));
	StopDateMS = Math.round(Date.now())
	StartDate = new Date(StartDateMS).toISOString().split('T')[0]
	StopDate = new Date(StopDateMS).toISOString().split('T')[0]

	tbl = [];
}