//************************************************************************************************
//es6 modules
//************************************************************************************************

import React, { useEffect, useState } from 'react';
import { ActivityIndicator, FlatList, Button, Image, StyleSheet, Text, View } from 'react-native';
import ReactDOM from 'react-dom';
import ReactDataGrid from 'react-data-grid';
import { Toolbar, Data, Filters } from "react-data-grid-addons";
import faker from "faker";
import PropTypes from 'prop-types';
import axios from "axios";
import { CSVLink, CSVDownload } from "react-csv";
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import "../assets/custom_styles.css";
import globalVars from "./globalVars.js"

//************************************************************************************************
// Javascript Start
//************************************************************************************************

var list = []

const NoError = 0
const StatusError = 1
const TransactionTypeError = 2
const AmountError = 4
const LocationError = 8
const SiteError = 16
const FtransError = 32
const MissingFromFiscal = 64
const MissingFromBank = 128   

function printError(e) { 
	var err = ""

    if((e & StatusError) != 0) { 
        err = err + "Status Error"  
    }
    if((e & TransactionTypeError) != 0) { 
        err = err + "Type Error"
    }
    if((e & AmountError) != 0) { 
        err = err + "Amount Error"
    }
    if((e & LocationError) != 0) { 
        err = err + "Location Error"
    }
    if((e & SiteError) != 0) { 
        err = err + "Site Error"
    }
    if((e & FtransError) != 0) { 
        err = err + "Ftrans Error"
    }
    if((e & MissingFromFiscal) != 0) { 
        err = err + "Missing from Fiscal Error"
    }
    if((e & MissingFromBank) != 0) { 
        err = err + "Missing from Bank Error"
    }

    return err
}

//************************//
// Global Variables Start //
//************************//

var StartDateMS = Date.now() - 2*604800000;
var StopDateMS = Date.now()

var StartDate = new Date(StartDateMS).toISOString().split('T')[0]
var StopDate = new Date(StopDateMS).toISOString().split('T')[0]

var filteredDepositRows
var filteredTransactionRows

var filteredIRows
var filteredMRows

var depositTable = [];
var mTbl = []
var iTbl = []

var rendType = false
var color = 'blue'
var style = 'custom-style1'	

//***********************//
// Global Variables Stop //
//***********************//

const hostEnum = [
	{ value: 1,    label: 'HOST_COMDATA'  },
	{ value: 2,    label: 'HOST_TCH'      },
	{ value: 4,    label: 'HOST_EFS'      },
	{ value: 8,    label: 'HOST_TCHECK'   },
	{ value: 16,   label: 'HOST_WORLDPAY' },
	{ value: 32,   label: 'HOST_RELAY'    },
]

const statusEnum = [
//	{ value: 1,   label: 'CREATED'             	},
//	{ value: 2,   label: 'DECLINED'            	},
//	{ value: 4,   label: 'CANCELED'            	},
	{ value: 8,   label: 'AUTHORIZED'          	},
//	{ value: 16,  label: 'PRE_AUTHORIZED'      	},
//	{ value: 32,  label: 'PRE_EDITED'          	},
//	{ value: 64,  label: 'PROMPTING_USER'		},      
	{ value: 128, label: 'MANUALLY_AUTHORIZED' 	},
//	{ value: 256, label: 'USERCANCELED'			},        
]

const selectors = Data.Selectors;

const {
	NumericFilter,
	AutoCompleteFilter,
	MultiSelectFilter,
	SingleSelectFilter
} = Filters;

const defaultColumnProperties = {
	filterable: true,
	width: 325,
	editable: true

};

const amountFormatter = ({ value }) => {
	return <div>${value}</div>;
};

const errorFormatter = ({value}) => { 
	var d = printError(value)
	return <div> {value}- {d} </div>
}

const depositColumns = [

	{ key: 'date',		name: 'Date' },
	{ key: 'hostID',	name: 'HostID' },
	{ key: 'depositID', name: 'DepositID' },
	{ key: 'amount_net',	name: 'Net Amount', filterRenderer: NumericFilter, formatter: amountFormatter },
	{ key: 'amount_gross',	name: 'Gross Amount', filterRenderer: NumericFilter, formatter: amountFormatter },
	{ key: 'amount_fee',	name: 'Fee Amount', filterRenderer: NumericFilter, formatter: amountFormatter },		

].map(c => ({ ...c, ...defaultColumnProperties }));

function createDepositRow(r) {
//	var date = new Date(r.Date); 
//	var s = date.toISOString().split('T')[0];
	return {
		date: r.DepositProcessingDate,
		hostID: hostFormatter(r.DepositHost),
		depositID: r.DepositID,
		amount_net: r.DepositAmountNetTotal / 100,
		amount_gross: r.DepositAmountGrossTotal / 100,
		amount_fee: r.DepositAmountFeeTotal / 100,
	};
}

function formatDepositTable(t) { 
	var ft = []

	for (let i = 0; i < t.length; i++) { 
		ft.push(createDepositRow(t[i]))
	}

	return ft
}

const handleFilterChange = filter => filters => {
	const newFilters = { ...filters };
	if (filter.filterTerm) {
		newFilters[filter.column.key] = filter;
	} else {
		delete newFilters[filter.column.key];
	}
	return newFilters;
};

function getValidFilterValues(rows, columnId) {
	return rows
		.map(r => r[columnId])
		.filter((item, i, a) => {
			return i === a.indexOf(item);
		});
}

function getRows(rows, filters) {
	return selectors.getRows({ rows, filters });
}

const RowRenderer = ({ renderBaseRow, ...props }) => {

	if(rendType) { 
		color = 'blue'
		style = 'custom-style1'	
	} else { 
		color = 'green'
		style = 'custom-style2'
	}

	if(filteredIRows[props.idx+1] !== undefined) { 
		if(props.row.guid != filteredIRows[props.idx+1].guid) { 
			rendType = !rendType
		} 
	}

	return <div className={style} style={{color: color}}>{renderBaseRow(props)}</div>;

};

const Columns = [

	{ key: 'txnID',					name: 'Load ID'	},
	{ key: 'date_sale',	 			name: 'Sale Date'	 },
	{ key: 'date_deposit',	 		name: 'Deposit Date'	 },
	{ key: 'type',		 			name: 'Host'	 },
	{ key: 'amount_net',	 		name: 'Amount Net',					filterRenderer: NumericFilter, formatter: amountFormatter },	
	{ key: 'amount_gross',	 		name: 'Amount Gross',				filterRenderer: NumericFilter, formatter: amountFormatter },	
	{ key: 'amount_fee',	 		name: 'Amount Fee',					filterRenderer: NumericFilter, formatter: amountFormatter },	
	{ key: 'subDept', 				name: 'Subdepartment' },		
	{ key: 'auth', 					name: 'Auth Number'},
	{ key: 'control_number', 		name: 'Control Number'},	
	{ key: 'purchaseOrderNumber',	name: 'PO #'	},	
	{ key: 'depositID', 			name: 'DepositID'},
	{ key: 'db', 					name: 'Database'},

].map(c => ({ ...c, ...defaultColumnProperties }));



function formatFiscalMissmatchTable(t) { 
	var ft = []

	if(t !== null) { 
		for (let i = 0; i < t.length; i++) { 
			ft.push(createFiscalRow(t[i]))
		}
	}

	return ft
}


//************************************************
// Axios Stuff Start
//************************************************

const hostFormatter = ( value ) => {
	var x = "No Valid Host Found"; 
	for(var i = 0; i < hostEnum.length; i++) { 
		if(value == hostEnum[i].value) { 
			x = hostEnum[i].label
		}
	} 

	return x;
};

function parseNumbers(r) { 
	if(r !== undefined) { 
		var dat = ""
		for(let i = 0; i < r.length; i++) { 
			dat += r[i] + " "
		}
		return dat; 
	} else { 
		return "No Po Numbers"
	}

}

function formatTransactionTable(t) { 
	var ft = []

	if(t !== null) { 
		for (let i = 0; i < t.length; i++) { 
			ft.push(createTransactionRow(t[i]))
		}
	}

	return ft
}

function createFiscalRow(r) { 
	return {
		type: hostFormatter(r.payHost),
//		amount_fee: //(r.Amount_fee / 100),
		amount_gross: (r.amount / 100),
//		amount_net: //(r.Amount_net / 100),		
		location: r.subDept,
//		site: r.Site,
//		ftrans: r.Ftrans,
		purchaseOrderNumber: parseNumbers(r.po_numbers),
//		guid: r.GUID,
		date_sale: r.date,
//		date_deposit: r.Date_deposit, 
//		auth: r.Auth_num,
//		control_number: r.Control_number,
		txnID: r.txnID,
		subDept: r.subDept,
//		depositID: r.Deposit_id,
		db: "Fiscal DB"
	};
}

function createTransactionRow(r) {

	return {
		type: hostFormatter(r.pay_Host),
		amount_fee: (r.Amount_fee / 100),
		amount_gross: (r.Amount_gross / 100),
		amount_net: (r.Amount_net / 100),		
		location: r.location,
		site: r.Site,
		ftrans: r.Ftrans,
		purchaseOrderNumber: parseNumbers(r.po_numbers),
		guid: r.GUID,
		date_sale: r.Date_sale,
		date_deposit: r.Date_deposit, 
		auth: r.Auth_num,
		control_number: r.Control_number,
		txnID: r.txnID,
		subDept: r.Subdep_num,
		depositID: r.Deposit_id,
		db: "Host DB"
	};
}

function sortFunction(a, b) {
    if (a.txnID === b.txnID) {
        return 0;
    }
    else {
        return (a.txnID > b.txnID) ? -1 : 1;
    }
}

//*****************\\
// Display On Load \\
//*****************\\

function DisplayBankDepositDataOnLoad() {

	const [value, onChange] = useState(new Date());	
	const [depositID, setDepositID] = useState(0);	

	const [depositRows, setDepositRows] = useState([]);
	const [IDataRows, setIRows] = useState([]);
	const [MDataRows, setMRows] = useState([]);

	const [depositFilters, setDepositFilters] = useState({});
	const [Ifilters, setIFilters] = useState({});
	const [Mfilters, setMFilters] = useState({});


	const [set,setSet] = useState(false); 

	if(!set) { 
		console.log('GET Request');
		const res = axios({
			method: 'get', 
			url: 'https://' + globalVars.OldAuthProvider + ':' + globalVars.BackendPort + '/GetGapDepositReport'
		})
		.then(res => {
			console.log(res)
			setDepositRows(formatDepositTable(res.data.DepositsWithErrors))
			setSet(true)
		})
		.catch(err => console.error(err));
	} 

	const logRow = t => {

		if (typeof t !== 'undefined') {

			const res = axios({
				method: 'get', 
				url: 'https://' + globalVars.OldAuthProvider + ':' + globalVars.BackendPort + '/GetGapDepositReportTrans?id=' + t.depositID
			})
			.then(res => {
				console.log("Trans res", res)
				var missmatch_recon  = formatTransactionTable(res.data.Error_missmatch_recon)
				var missmatch_fiscal = formatFiscalMissmatchTable(res.data.Error_missmatch_fiscal)
				var missmatch_data = missmatch_recon.concat(missmatch_fiscal)

	  			missmatch_data.sort(sortFunction)

				setIRows(missmatch_data)
				setMRows(formatTransactionTable(res.data.Error_missing_recon))



			})
			.catch(err => console.error("Dep:",err));

		}
 
	}


	const calChange = e => { 

	}	 

	filteredDepositRows = getRows(depositRows, depositFilters);
	filteredIRows = getRows(IDataRows, Ifilters);
	filteredMRows = getRows(MDataRows, Mfilters); 

	return ( 
		<View>
			<View>
				<Calendar 
					onChange={calChange}
					value={value}
					selectRange={true}
				/>
			</View>
			<View>
			<div><h3>Deposits</h3></div>			
				<div className="custom-style">
					<ReactDataGrid
						columns={depositColumns}
						rowGetter={i => filteredDepositRows[i]}
						rowsCount={filteredDepositRows.length}
						width={250}
						toolbar={<Toolbar enableFilter={true} />}
						onAddFilter={filter => setDepositFilters(handleFilterChange(filter))}
						onClearFilters={() => setDepositFilters({})}
						getValidFilterValues={columnKey => getValidFilterValues(rows, columnKey)}
						onRowClick={i => logRow(filteredDepositRows[i])}
						enableCellSelect={true}						
					/>
				</div>
			</View>
			<View><div> Deposit Count: {filteredDepositRows.length} </div></View>
			<View>
				<div><h3>Inconsistent Data</h3></div>
				<div>
					<ReactDataGrid
					columns={Columns}
					rowGetter={i => filteredIRows[i]}
					rowsCount={filteredIRows.length}
					toolbar={<Toolbar enableFilter={true} />}
					onAddFilter={filter => setIFilters(handleFilterChange(filter))}
					onClearFilters={() => setIFilters({})}
					getValidFilterValues={columnKey => getValidFilterValues(IDataRows, columnKey)}				
					rowRenderer={RowRenderer}
					enableCellSelect={true}
				 />
				</div>
			 </View>
			<View><div> Inconsistent Data Count: {filteredIRows.length} </div></View>			 
			 <View>
				<div><h3>Missing Data</h3></div>
				<div className="custom-style">
					<ReactDataGrid
					columns={Columns}
					rowGetter={i => filteredMRows[i]}
					rowsCount={filteredMRows.length}
					toolbar={<Toolbar enableFilter={true} />}
					onAddFilter={filter => setMFilters(handleFilterChange(filter))}
					onClearFilters={() => setMFilters({})}
					getValidFilterValues={columnKey => getValidFilterValues(MDataRows, columnKey)}	
					rowRenderer={RowRenderer}
					enableCellSelect={true}								
					/>
				</div>
			</View>
			<View><div> Missing Data Count: {filteredMRows.length} </div></View>			 
		</View>
	)

}

//************************************************
// Axios Stuff Stop
//************************************************

//************************************************************************************************
// Javascript Stop
//************************************************************************************************

//************************************************************************************************
// Main Start
//************************************************************************************************

export default ({history}) => (

	<View>
		<View> <h3> Gap Deposit Report From {StartDate} to {StopDate} </h3> </View>
		<Button title="Fiscal Report" onPress={() => {
			resetData()
			history.push("/pages/FiscalReport")}
		}></Button>
		<Button title="Host Transaction Report" onPress={() => {
			resetData()	 
			history.push("/pages/BankReport")}
		}></Button>
		<Button title="Host Deposit Report" onPress={() => {
			resetData()
			history.push("/pages/BankDepositReport")}
		}></Button>
		<Button title="Gap Report"	onPress={() => {
			resetData()
			history.push("/pages/GapReport")}
		}></Button>
		<Button title="Gap Deposit Report"	onPress={() => {
			resetData()
			history.push("/pages/GapDepositReport")}
		}></Button>
		<Button title="Support" onPress={() => {
			resetData()
			history.push("/pages/SupportFiscalReport")}
		}></Button>				
		<View> {DisplayBankDepositDataOnLoad()} </View>
		<CSVLink data={filteredDepositRows}>Download Deposit Data</CSVLink>
		<CSVLink data={filteredIRows}>Download Inconsistent Data</CSVLink>
		<CSVLink data={filteredMRows}>Download Missing Data</CSVLink>			
	</View>

);

//************************************************************************************************
// Main Stop
//************************************************************************************************

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#fff',
		alignItems: 'strech',
		justifyContent: 'center',
		padding: 20
	},
});

function DisplayList(t) {

	return (
		<div> {v} </div>
	);

}

function resetData() { 
	StartDateMS = Math.round((Date.now() - 2*604800000)/1000);
	StopDateMS = Math.round(Date.now())
	StartDate = new Date(StartDateMS).toISOString().split('T')[0]
	StopDate = new Date(StopDateMS).toISOString().split('T')[0]
	depositTable = [];
	mTbl = []
	iTbl = []
}