//************************************************************************************************
//es6 modules
//************************************************************************************************

import React, { useEffect, useState } from 'react';
import { ActivityIndicator, FlatList, Button, Image, StyleSheet, Text, View } from 'react-native';
import ReactDOM from 'react-dom';
import ReactDataGrid from 'react-data-grid';
import { Toolbar, Data, Filters } from "react-data-grid-addons";
import faker from "faker";
import PropTypes from 'prop-types';
import axios from "axios";
import { CSVLink, CSVDownload } from "react-csv";
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import globalVars from "./globalVars.js"

//************************************************************************************************
// Javascript Start
//************************************************************************************************

const NoError = 0
const StatusError = 1
const TransactionTypeError = 2
const AmountError = 4
const LocationError = 8
const SiteError = 16
const FtransError = 32
const MissingFromFiscal = 64
const MissingFromBank = 128   

function printError(e) { 
	var err = ""

    if((e & StatusError) != 0) { 
        err = err + "Status Error"  
    }
    if((e & TransactionTypeError) != 0) { 
        err = err + "Type Error"
    }
    if((e & AmountError) != 0) { 
        err = err + "Amount Error"
    }
    if((e & LocationError) != 0) { 
        err = err + "Location Error"
    }
    if((e & SiteError) != 0) { 
        err = err + "Site Error"
    }
    if((e & FtransError) != 0) { 
        err = err + "Ftrans Error"
    }
    if((e & MissingFromFiscal) != 0) { 
        err = err + "Missing from Fiscal Error"
    }
    if((e & MissingFromBank) != 0) { 
        err = err + "Missing from Bank Error"
    }

    return err
}


//************************//
// Global Variables Start //
//************************//

var StartDateMS = Date.now() - 2*604800000;
var StopDateMS = Date.now()

var StartDate = new Date(StartDateMS).toISOString().split('T')[0]
var StopDate = new Date(StopDateMS).toISOString().split('T')[0]

var filteredIRows
var filteredMRows

var mTbl = []
var iTbl = []

var rendType = false
var color = 'blue'
var style = 'custom-style1'

//***********************//
// Global Variables Stop //
//***********************//

const selectors = Data.Selectors;

const {
	NumericFilter,
	AutoCompleteFilter,
	MultiSelectFilter,
	SingleSelectFilter
} = Filters;

const defaultColumnProperties = {
	filterable: true,
	width: 350,
	editable: true
};

const amountFormatter = ({ value }) => {
	return <div>${value}</div>;
};

const errorFormatter = ({value}) => { 
	var d = printError(value)
	return <div> {value} - {d} </div>
}

const selectFormatter = ({value}) => { 
	return (
		<input
			name="isGoing"						
			type="checkbox"
		/>
	)
}

const IColumns = [
	{ key: 'txnID',					name: 'Load ID'	},
	{ key: 'date_sale',	 			name: 'Sale Date'	 },
	{ key: 'date_deposit',	 		name: 'Deposit Date'	 },
	{ key: 'type',		 			name: 'Host'	 },
	{ key: 'amount_net',	 		name: 'Amount Net',					filterRenderer: NumericFilter, formatter: amountFormatter },	
	{ key: 'amount_gross',	 		name: 'Amount Gross',				filterRenderer: NumericFilter, formatter: amountFormatter },	
	{ key: 'amount_fee',	 		name: 'Amount Fee',					filterRenderer: NumericFilter, formatter: amountFormatter },	
	{ key: 'subDept', 				name: 'Subdepartment' },		
	{ key: 'auth', 					name: 'Auth Number'},
	{ key: 'control_number', 		name: 'Control Number'},	
	{ key: 'purchaseOrderNumber',	name: 'PO #'	},	
	{ key: 'depositID', 			name: 'DepositID'},
	{ key: 'db', 					name: 'Database'},
].map(c => ({ ...c, ...defaultColumnProperties }));

const MColumns = [
	{ key: 'txnID',					name: 'Load ID'	},
	{ key: 'date_sale',	 			name: 'Sale Date'	 },
	{ key: 'date_deposit',	 		name: 'Deposit Date'	 },
	{ key: 'type',		 			name: 'Host'	 },
	{ key: 'amount_net',	 		name: 'Amount Net',					filterRenderer: NumericFilter, formatter: amountFormatter },	
	{ key: 'amount_gross',	 		name: 'Amount Gross',				filterRenderer: NumericFilter, formatter: amountFormatter },	
	{ key: 'amount_fee',	 		name: 'Amount Fee',					filterRenderer: NumericFilter, formatter: amountFormatter },	
	{ key: 'subDept', 				name: 'Subdepartment' },		
	{ key: 'auth', 					name: 'Auth Number'},
	{ key: 'control_number', 		name: 'Control Number'},	
	{ key: 'purchaseOrderNumber',	name: 'PO #'	},	
	{ key: 'depositID', 			name: 'DepositID'},
	{ key: 'db', 					name: 'Database'},
].map(c => ({ ...c, ...defaultColumnProperties }));

const hostEnum = [
	{ value: 1,    label: 'HOST_COMDATA'  },
	{ value: 2,    label: 'HOST_TCH'      },
	{ value: 4,    label: 'HOST_EFS'      },
	{ value: 8,    label: 'HOST_TCHECK'   },
	{ value: 16,   label: 'HOST_WORLDPAY' },
	{ value: 32,   label: 'HOST_RELAY'    },
]

const hostFormatter = ( value ) => {
	var x = "No Valid Host Found"; 
	for(var i = 0; i < hostEnum.length; i++) { 
		if(value == hostEnum[i].value) { 
			x = hostEnum[i].label
		}
	} 

	return x;
};

const statusEnum = [
//	{ value: 1,   label: 'CREATED'             	},
//	{ value: 2,   label: 'DECLINED'            	},
//	{ value: 4,   label: 'CANCELED'            	},
	{ value: 8,   label: 'AUTHORIZED'          	},
//	{ value: 16,  label: 'PRE_AUTHORIZED'      	},
//	{ value: 32,  label: 'PRE_EDITED'          	},
//	{ value: 64,  label: 'PROMPTING_USER'		},      
	{ value: 128, label: 'MANUALLY_AUTHORIZED' 	},
//	{ value: 256, label: 'USERCANCELED'			},        
]

function getRows(rows, filters) {
	return selectors.getRows({ rows, filters });
}

const handleFilterChange = filter => filters => {
	const newFilters = { ...filters };
	if (filter.filterTerm) {
		newFilters[filter.column.key] = filter;
	} else {
		delete newFilters[filter.column.key];
	}
	return newFilters;
};

function getValidFilterValues(rows, columnId) {
	return rows
	.map(r => r[columnId])
	.filter((item, i, a) => {
		return i === a.indexOf(item);
	});
}

	

const RowRenderer = ({ renderBaseRow, ...props }) => {

	if(rendType) { 
		color = 'blue'
		style = 'custom-style1'	
	} else { 
		color = 'green'
		style = 'custom-style2'
	}

	if(filteredIRows[props.idx+1] !== undefined) { 
		if(props.row.guid != filteredIRows[props.idx+1].guid) { 
			rendType = !rendType
		} 
	}

	return <div className={style} style={{color: color}}>{renderBaseRow(props)}</div>;

};

//************************************************
// Axios Stuff Start
//************************************************

function create_Recon_Row(r) {
//	var date = new Date(r.Date); 
//	var s = date.toISOString().split('T')[0];
//	console.log(r)
	return {
		status: r.Status,
		type: hostFormatter(r.pay_Host),
		amount_fee: (r.Amount_fee / 100),
		amount_gross: (r.Amount_gross / 100),
		amount_net: (r.Amount_net / 100),		
		location: r.location,
		site: r.Site,
		ftrans: r.Ftrans,
		purchaseOrderNumber: parseNumbers(r.po_numbers),
		guid: r.GUID,
		date_sale: r.Date_sale,
		date_deposit: r.Date_deposit, 
		auth: r.Auth_num,
		control_number: r.Control_number,
		txnID: r.txnID,
		subDept: r.Subdep_num,
		depositID: r.Deposit_id,
		db: "Host DB"
	};
}


//amount: 22500
//carrier: "Test WebMWS"


function create_Fiscal_Row(r) {
//	var date = new Date(r.Date); 
//	var s = date.toISOString().split('T')[0];
//	console.log(r)
	return {
		type: hostFormatter(r.payHost) ,
//		amount_fee:  , 
		amount_gross: r.amount / 100,
//		amount_net: "Not recorded",		
		location: r.subDept ,
//		site: r. ,
//		ftrans: r. ,
		purchaseOrderNumber: parseNumbers(r.po_numbers),
//		guid: r. ,
		date_sale: r.date ,
//		date_deposit: r. , 
//		auth: r. ,
//		control_number: r. ,
		txnID: r.txnID,
		subDept: r.subDept,
		db: "Fiscal DB"
	};
}

function format_Recon_Table(t,s,e) { 
	var ft = []

	for (let i = 0; i < t.length; i++) { 
		ft.push(create_Recon_Row(t[i]))
	}

	return ft
}

function format_Fiscal_Table(t,s,e) { 
	var ft = []

	for (let i = 0; i < t.length; i++) { 
		ft.push(create_Fiscal_Row(t[i]))
	}

	return ft
}

function parseNumbers(r) { 
	if(r !== undefined) { 
		var dat = ""
		for(let i = 0; i < r.length; i++) { 
			dat += r[i] + " "
		}
		return dat; 
	} else { 
		return "No Po Numbers"
	}

}

function sortFunction(a, b) {
    if (a.txnID === b.txnID) {
        return 0;
    }
    else {
        return (a.txnID > b.txnID) ? -1 : 1;
    }
}

//*****************\\
// Display On Load \\
//*****************\\

function DisplayFiscalDataOnLoad() {

	const [IF_Rows, set_IF_Rows] = useState([]);
	const [IP_Rows, set_IP_Rows] = useState([]);

	const [MF_DataRows, set_MF_Rows] = useState([]);
	const [MP_DataRows, set_MP_Rows] = useState([]);

	const [IDataRows, setIDataRows] = useState([]);
	const [MDataRows, setMDataRows] = useState([]);

	const [value, onChange] = useState(new Date());	
	const [Ifilters, setIFilters] = useState({});
	const [Mfilters, setMFilters] = useState({});

	const [set,setSet] = useState(false); 

	if(!set) { 
		console.log('GET Request');
		const res = axios({
			method: 'get', 
			url: 'https://' + globalVars.OldAuthProvider + ':' + globalVars.BackendPort + '/FindInconsistantData'
		})
		.then(res => {

			console.log(res)
			
			var missing_fiscal   = format_Fiscal_Table(res.data.Error_missing_fiscal,0,0)
			var missing_recon    = format_Recon_Table (res.data.Error_missing_recon,0,0)
			var missmatch_fiscal = format_Fiscal_Table(res.data.Error_missmatch_fiscal,0,0)
			var missmatch_recon  = format_Recon_Table (res.data.Error_missmatch_recon,0,0)

//			console.log("missing_fiscal",missing_fiscal)
//			console.log("missing_recon",missing_recon)
//			console.log("missmatch_fiscal",missmatch_fiscal)
//			console.log("missmatch_recon",missmatch_recon)			

			var missing_trans   =   missing_fiscal.concat(missing_recon)
			var missmatch_trans = missmatch_fiscal.concat(missmatch_recon)

  			  missing_trans.sort(sortFunction)
			missmatch_trans.sort(sortFunction)

			setIDataRows(missmatch_trans)
			setMDataRows(missing_trans)

			setSet(true)

		})
		.catch(err => console.error(err));
	} 


	filteredIRows = getRows(IDataRows, Ifilters);
	filteredMRows = getRows(MDataRows, Mfilters);	 

	const calChange = e => { 
		onChange(e)
		StartDate = e[0].toISOString().split('T')[0];
		StopDate = e[1].toISOString().split('T')[0];
		StartDateMS = Date.parse(StartDate)
		StopDateMS = Date.parse(StopDate)
		setRows(formatTable(tbl,StartDateMS,StopDateMS)) 
	};

	const changeStart = e => {
		StartDate = e.target.value
		StartDateMS = Date.parse(StartDate)
		if( isNaN(StartDateMS)) { 
			StartDateMS = Date.now() - 2*604800000;
		}
		setRows(formatTable(tbl,StartDateMS,StopDateMS))
	};

	const changeStop = e => {
		StopDate = e.target.value
		StopDateMS = Date.parse(StopDate)
		if(isNaN(StopDateMS)) { 
			StopDateMS = Date.now()
		}
		setRows(formatTable(tbl,StartDateMS,StopDateMS))
	};

	return (
		<View>			
			<View>
				<Calendar 
					onChange={calChange}
					value={value}
					selectRange={true}
				/>
			</View>			
			<View style={styles.container}>
				<form>
					<label>From Date:</label>
					<input type="text" name='FromDate' onChange={changeStart}/>
					<label>To Date: </label>
					<input type="text" name='ToDate' onChange={changeStop}/>
				</form>
			</View>
			<View>
				<div><h3>Inconsistent Data</h3></div>
				<div>
					<ReactDataGrid
					columns={IColumns}
					rowGetter={i => filteredIRows[i]}
					rowsCount={filteredIRows.length}
					toolbar={<Toolbar enableFilter={true} />}
					onAddFilter={filter => setIFilters(handleFilterChange(filter))}
					onClearFilters={() => setIFilters({})}
					getValidFilterValues={columnKey => getValidFilterValues(IDataRows, columnKey)}				
					rowRenderer={RowRenderer}
					enableCellSelect={true}
				 />
				</div>
			 </View>
			 <View>
				<div><h3>Missing Data</h3></div>
				<div className="custom-style">
					<ReactDataGrid
					columns={MColumns}
					rowGetter={i => filteredMRows[i]}
					rowsCount={filteredMRows.length}
					toolbar={<Toolbar enableFilter={true} />}
					onAddFilter={filter => setMFilters(handleFilterChange(filter))}
					onClearFilters={() => setMFilters({})}
					getValidFilterValues={columnKey => getValidFilterValues(MDataRows, columnKey)}		
					enableCellSelect={true}		
					rowRenderer={RowRenderer}
					/>
				</div>
			</View>
		</View>
	);
}

//************************************************
// Axios Stuff Stop
//************************************************

//************************************************************************************************
// Javascript Stop
//************************************************************************************************

//************************************************************************************************
// Main Start
//************************************************************************************************

export default ({history}) => (

	<View>
		<View> <h3> Gap Report From {StartDate} to {StopDate} </h3> </View>
		<Button title="Fiscal Report" onPress={() => {
			resetData()
			history.push("/pages/FiscalReport")}
		}></Button>
		<Button title="Host Transaction Report" onPress={() => {
			resetData()	 
			history.push("/pages/BankReport")}
		}></Button>
		<Button title="Host Deposit Report" onPress={() => {
			resetData()
			history.push("/pages/BankDepositReport")}
		}></Button>
		<Button title="Gap Report"	onPress={() => {
			resetData()
			history.push("/pages/GapReport")}
		}></Button>
		<Button title="Gap Deposit Report"	onPress={() => {
			resetData()
			history.push("/pages/GapDepositReport")}
		}></Button>	
		<Button title="Support" onPress={() => {
			resetData()
			history.push("/pages/SupportFiscalReport")}
		}></Button>									
		<View> {DisplayFiscalDataOnLoad()} </View>
		<CSVLink data={filteredIRows}>Download Inconsistent Data</CSVLink>
		<CSVLink data={filteredMRows}>Download Missing Data</CSVLink>			
	</View>

);

//************************************************************************************************
// Main Stop
//************************************************************************************************

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#fff',
		alignItems: 'strech',
		justifyContent: 'center',
		padding: 20
	},fixToText: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	}
});

function resetData() { 
	StartDateMS = Math.round((Date.now() - 2*604800000)/1000);
	StopDateMS = Math.round(Date.now())
	StartDate = new Date(StartDateMS).toISOString().split('T')[0]
	StopDate = new Date(StopDateMS).toISOString().split('T')[0]
	mTbl = []
	iTbl = []
}