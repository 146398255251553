//************************************************************************************************
//es6 modules
//************************************************************************************************

import React, { useEffect, useState } from 'react';
import { ActivityIndicator, FlatList, Button, Image, StyleSheet, Text, View } from 'react-native';
import ReactDOM from 'react-dom';
import ReactDataGrid from 'react-data-grid';
import { Toolbar, Data, Filters } from "react-data-grid-addons";
import faker from "faker";
import PropTypes from 'prop-types';
import axios from "axios";
import { CSVLink, CSVDownload } from "react-csv";
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import "../assets/custom_styles.css";
import globalVars from "./globalVars.js"

//************************************************************************************************
// Javascript Start
//************************************************************************************************

//************************//
// Global Variables Start //
//************************//

var StartDateMS = Date.now() - 2*604800000;
var StopDateMS = Date.now()

var StartDate = new Date(StartDateMS).toISOString().split('T')[0]
var StopDate = new Date(StopDateMS).toISOString().split('T')[0]

var filteredDepositRows
var filteredTransactionRows

var transactionTable = [];
var depositTable = [];




//***********************//
// Global Variables Stop //
//***********************//

const selectors = Data.Selectors;

const {
	NumericFilter,
	AutoCompleteFilter,
	MultiSelectFilter,
	SingleSelectFilter	
} = Filters;

const defaultColumnProperties = {
	filterable: true,
	width: 325,
	editable: true
};

const amountFormatter = ({ value }) => {
	return <div>{value}$</div>;
};

const hostEnum = [
	{ value: 1,    label: 'HOST_COMDATA'  },
	{ value: 2,    label: 'HOST_TCH'      },
	{ value: 4,    label: 'HOST_EFS'      },
	{ value: 8,    label: 'HOST_TCHECK'   },
	{ value: 16,   label: 'HOST_WORLDPAY' },
	{ value: 32,   label: 'HOST_RELAY'    },
]

const hostFormatter = ( value ) => {
	console.log("HOST FORMATER VALUE",value);
	var x = "No Valid Host Found"; 
	for(var i = 0; i < hostEnum.length; i++) { 
		if(value == hostEnum[i].value) { 
			x = hostEnum[i].label
		}
	} 

	return x;
};

const depositColumns = [

	{ key: 'date',		name: 'Date' },
	{ key: 'hostID',	name: 'HostID' },
	{ key: 'depositID', name: 'DepositID' },
	{ key: 'amount_net',	name: 'Net Amount', filterRenderer: NumericFilter, formatter: amountFormatter },
	{ key: 'amount_gross',	name: 'Gross Amount', filterRenderer: NumericFilter, formatter: amountFormatter },
	{ key: 'amount_fee',	name: 'Fee Amount', filterRenderer: NumericFilter, formatter: amountFormatter },		

].map(c => ({ ...c, ...defaultColumnProperties }));


const transactionColumns = [

	{ key: 'txnID',					name: 'Load ID'	},
	{ key: 'date_sale',	 			name: 'Sale Date'	 },
	{ key: 'date_deposit',	 		name: 'Deposit Date'	 },
	{ key: 'type',		 			name: 'Host'	 },
	{ key: 'amount_net',	 		name: 'Amount Net',					filterRenderer: NumericFilter, formatter: amountFormatter },	
	{ key: 'amount_gross',	 		name: 'Amount Gross',				filterRenderer: NumericFilter, formatter: amountFormatter },	
	{ key: 'amount_fee',	 		name: 'Amount Fee',					filterRenderer: NumericFilter, formatter: amountFormatter },	
	{ key: 'subDept', 				name: 'Subdepartment' },		
	{ key: 'auth', 					name: 'Auth Number'},
	{ key: 'control_number', 		name: 'Control Number'},	
	{ key: 'purchaseOrderNumber',	name: 'PO #'	},	

].map(c => ({ ...c, ...defaultColumnProperties }));

const handleFilterChange = filter => filters => {
	const newFilters = { ...filters };
	if (filter.filterTerm) {
		newFilters[filter.column.key] = filter;
	} else {
		delete newFilters[filter.column.key];
	}
	return newFilters;
};

function getValidFilterValues(rows, columnId) {
	return rows
	.map(r => r[columnId])
	.filter((item, i, a) => {
		return i === a.indexOf(item);
	});
}

function getRows(rows, filters) {
	return selectors.getRows({ rows, filters });
}

function createTransactionRow(r) {
//	var date = new Date(r.Date); 
//	var s = date.toISOString().split('T')[0];
	return {
		status: r.Status,
		type: hostFormatter(r.pay_Host),
		amount_fee: (r.Amount_fee / 100),
		amount_gross: (r.Amount_gross / 100),
		amount_net: (r.Amount_net / 100),		
		location: r.location,
		site: r.Site,
		ftrans: r.Ftrans,
		purchaseOrderNumber: parseNumbers(r.po_numbers),
		guid: r.GUID,
		date_sale: r.Date_sale,
		date_deposit: r.Date_deposit, 
		auth: r.Auth_num,
		control_number: r.Control_number,
		txnID: r.txnID,
		subDept: r.Subdep_num
	};
}


function parseNumbers(r) { 
	if(r !== undefined) { 
		var dat = ""
		for(let i = 0; i < r.length; i++) { 
			dat += r[i] + " "
		}
		return dat; 
	} else { 
		return "No Po Numbers"
	}

}


function createFakeRow(id,host) {
//	console.log(faker.date.recent().toString())
	var x =	hostEnum.sort(() => (Math.random() > .5) ? 1 : -1);

	return {
		type: host,
		amount: faker.finance.amount(),
		location: faker.address.city(),
		site: 350,
		ftrans: "test",
		purchaseOrderNumber: 66350,
		guid: "test",
		auth: Math.round(faker.finance.amount()*100),
		date: faker.date.recent().toString(),
		depositID: id
	};
}

function createDepositRow(r) {
//	var date = new Date(r.Date); 
//	var s = date.toISOString().split('T')[0];
	return {
		date: r.DepositProcessingDate,
		hostID: hostFormatter(r.pay_Host),
		depositID: r.DepositID,
		amount_net: r.DepositAmountNetTotal / 100,
		amount_gross: r.DepositAmountGrossTotal / 100,
		amount_fee: r.DepositAmountFeeTotal / 100,
	};
}

function createFakeDepositRow() {
//	var date = new Date(r.Date); 
//	var s = date.toISOString().split('T')[0];

	var x =	hostEnum.sort(() => (Math.random() > .5) ? 1 : -1);

	return {
		date: faker.date.recent().toString(),
		hostID: x[0].label,
		depositID: Math.round(faker.finance.amount()*100),
		amount: faker.finance.amount()
	};
}

function formatTransactionTable(t) { 
	var ft = []

	for (let i = 0; i < t.length; i++) { 
		ft.push(createTransactionRow(t[i]))
	}

	return ft
}

function formatDepositTable(t,s,e) { 
	var ft = []

	for (let i = 0; i < t.length; i++) { 
//		if(s < t[i].Date && t[i].Date < e) { 
			ft.push(createDepositRow(t[i]))
//		}
	}

	return ft
}

function formatFakeTransTable(id,host) { 
	var ft = []; 

	for (let i = 0; i < 100; i++) { 
		ft.push(createFakeRow(id,host))
	} 	
	return ft; 
}

function formatFakeDepositTable() { 
	var ft = []; 

	for (let i = 0; i < 100; i++) { 
		ft.push(createFakeDepositRow())
	} 	
	return ft; 
}

//************************************************
// Axios Stuff Start
//************************************************

//*****************\\
// Display On Load \\
//*****************\\

function DisplayBankDepositDataOnLoad() {

	const [value, onChange] = useState(new Date());	
	const [depositID, setDepositID] = useState(0);	

	const [depositRows, setDepositRows] = useState([]);
	const [transactionRows, setTransactionRows] = useState([]);

	const [depositFilters, setDepositFilters] = useState({});
	const [transactionFilters, setTransactionFilters] = useState({});	 

	const [calcNetTotal,setCalcNetTotal] = useState(0);  
	const [calcGrossTotal,setCalcGrossTotal] = useState(0);  

/*



	if( transactionTable.length == 0 ) { 
		const res = axios({
			method: 'get', 
			url: 'https://' + globalVars.BackendIP + ':' + globalVars.BackendPort + '/BankData.json'
		})
		.then(res => {
			console.log("Transaction res", res)
			transactionTable = res.data.Table
			setTransactionRows(formatTransactionTable(transactionTable,StartDateMS,StopDateMS,depositID)) // Take recieved data and create a table which the ReactDataGrid can use. 
		})
		.catch(err => console.error(err));
	}		
*/
/*
	if(depositRows && depositRows.length > 0) { 

	} else { 
		setDepositRows(formatFakeDepositTable())
	}
*/	

	if( depositTable.length == 0 ) { 
		const res = axios({
			method: 'get', 
			url: 'https://' + globalVars.OldAuthProvider + ':' + globalVars.BackendPort + '/GetDepositData'
		})
		.then(res => {
			console.log("Deposit res", res)
			depositTable = res.data
			setDepositRows(formatDepositTable(depositTable,StartDateMS,StopDateMS)) // Take recieved data and create a table which the ReactDataGrid can use. 
		})
		.catch(err => console.error("Dep:",err));
	} else { 
		console.log("depositTable is set.")
	}

	const logRow = t => {

		if (typeof t !== 'undefined') {
			setDepositID(t.depositID)
//			setTransactionRows(formatTransactionTable(transactionTable,StartDateMS,StopDateMS,t.depositID)) // Take recieved data and create a table which the ReactDataGrid can use. 
//			setTransactionRows(formatFakeTransTable(t.depositID,t.hostID))

			const res = axios({
				method: 'get', 
				url: 'https://' + globalVars.OldAuthProvider + ':' + globalVars.BackendPort + '/GetDepositTrans?id=' + t.depositID
			})
			.then(res => {
				console.log("Trans res", res)
				transactionTable = res.data 

				var netTotal   = 0; 
				var grossTotal = 0; 

				for (let i = 0; i < transactionTable.length; i++) { 
					if(transactionTable[i].Amount_gross > 0) { 
						grossTotal += transactionTable[i].Amount_gross
					}
				} 
				console.log(grossTotal)
				setCalcGrossTotal(grossTotal)				
				
				setTransactionRows(formatTransactionTable(transactionTable,StartDateMS,StopDateMS)) // Take recieved data and create a table which the ReactDataGrid can use. 
			})
			.catch(err => console.error("Dep:",err));



		}
 
	}

	const calChange = e => { 
		onChange(e)
		StartDate = e[0].toISOString().split('T')[0];
		StopDate = e[1].toISOString().split('T')[0];
		StartDateMS = Date.parse(StartDate)
		StopDateMS = Date.parse(StopDate)
		setDepositRows(formatDepositTable(depositTable,StartDateMS,StopDateMS)) // Take recieved data and create a table which the ReactDataGrid can use.			 
		setTransactionRows(formatTransactionTable(transactionTable,StartDateMS,StopDateMS,depositID)) // Take recieved data and create a table which the ReactDataGrid can use. 
	}	 

	filteredDepositRows = getRows(depositRows, depositFilters);
	filteredTransactionRows = getRows(transactionRows, transactionFilters);

	return ( 
	<View>
		<View>
		<Calendar 
			onChange={calChange}
			value={value}
			selectRange={true}
		/>
		</View>
		<View> 
			<div>Calculated Gross: {calcGrossTotal/100}$</div>			
		</View> 
		<View>
		<div><h3>Deposits</h3></div>						
			<div className="custom-style">
			<ReactDataGrid
				columns={depositColumns}
				rowGetter={i => filteredDepositRows[i]}
				rowsCount={filteredDepositRows.length}
				width={250}
				toolbar={<Toolbar enableFilter={true} />}
				onAddFilter={filter => setDepositFilters(handleFilterChange(filter))}
				onClearFilters={() => setDepositFilters({})}
				getValidFilterValues={columnKey => getValidFilterValues(rows, columnKey)}
				onRowClick={i => logRow(filteredDepositRows[i])}
				enableCellSelect={true}
			/>
			</div>
		</View>
		<View>
		<div><h3>Transactions</h3></div>							
			<div className="custom-style">
			<ReactDataGrid
				columns={transactionColumns}
				rowGetter={i => filteredTransactionRows[i]}
				rowsCount={filteredTransactionRows.length}
				width={250}
				toolbar={<Toolbar enableFilter={true} />}
				onAddFilter={filter => setTransactionFilters(handleFilterChange(filter))}
				onClearFilters={() => setTransactionFilters({})}
				getValidFilterValues={columnKey => getValidFilterValues(rows, columnKey)}
				enableCellSelect={true}
			/>
			</div>
		</View>
		</View>
	)

 

}

//************************************************
// Axios Stuff Stop
//************************************************

//************************************************************************************************
// Javascript Stop
//************************************************************************************************

//************************************************************************************************
// Main Start
//************************************************************************************************

export default ({history}) => (

	<View>
		<View> <h3> Host Deposit Data From {StartDate} to {StopDate} </h3> </View>
		<Button title="Fiscal Report"	onPress={() => {
			resetData()
			history.push("/pages/FiscalReport")}
		}></Button>
		<Button title="Host Transaction Report"	onPress={() => {
			resetData()		
			history.push("/pages/BankReport")}
		}></Button>
		<Button title="Host Deposit Report"	onPress={() => {
			resetData()
			history.push("/pages/BankDepositReport")}
		}></Button>
		<Button title="Gap Report"	onPress={() => {
			resetData()
			history.push("/pages/GapReport")}
		}></Button>
		<Button title="Gap Deposit Report"	onPress={() => {
			resetData()
			history.push("/pages/GapDepositReport")}
		}></Button>			
		<Button title="Support" onPress={() => {
			resetData()
			history.push("/pages/SupportFiscalReport")}
		}></Button>				
		<View> {DisplayBankDepositDataOnLoad()} </View>
		<CSVLink data={filteredDepositRows}>Download Deposit Data</CSVLink>
		<CSVLink data={filteredTransactionRows}>Download Bank Data</CSVLink>
	</View>

);

//************************************************************************************************
// Main Stop
//************************************************************************************************

const styles = StyleSheet.create({
	container: {
	flex: 1,
	backgroundColor: '#fff',
	alignItems: 'strech',
	justifyContent: 'center',
	padding: 20
	},
});

function DisplayList(t) {

	return (
		<div> {v} </div>
	);

}

function resetData() { 
	StartDateMS = Math.round((Date.now() - 2*604800000)/1000);
	StopDateMS = Math.round(Date.now())
	StartDate = new Date(StartDateMS).toISOString().split('T')[0]
	StopDate = new Date(StopDateMS).toISOString().split('T')[0]
	transactionTable = [];
	depositTable = [];
}

