//************************************************************************************************
//es6 modules
//************************************************************************************************

import React, {  } from "react";
import {
  Button,
  View,
} from "react-native";
import Cookies from "universal-cookie";
import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "./../Configs/authConfig";
import { MsalContext } from "@azure/msal-react";



//************************************************************************************************
// Main Start
//************************************************************************************************

class Login extends React.Component {
	static contextType = MsalContext;
	constructor(props) {
    const cookies = new Cookies();

    super(props);

    this.state = {
      Username: cookies.get("name") || "",
      Password: "",
      loginCon: "",
      c: cookies,
    };

	// const { instance, accounts, inProgress } = useMsal();

    this.setUsername = this.setUsername.bind(this);

    this.setPassword = this.setPassword.bind(this);
  }


  setUsername(e) {
    this.setState({ Username: e.target.value });
  }

  setPassword(e) {
    this.setState({ Password: e.target.value });
  }

  msalLogin = () => {
    const msalInstance = this.context.instance;
    msalInstance.loginRedirect()
  }

  render() {
    const { history } = this.props;
	  const isAuthenticated = this.context.accounts.length > 0;
    const inProgress = this.context.inProgress;

    const showLogin = !isAuthenticated && inProgress == "none"


    return (

			<View>
				{showLogin && <Button title="Login" onPress={this.msalLogin}></Button>}
				<div>
				{ isAuthenticated && inProgress == "none" && (
					<div>
					<Button
						title="Support"
						onPress={() =>
							this.props.history.push("/pages/SupportFiscalReport")
						}
						></Button>
					<Button
						title="Support (Dark Mode)"
						onPress={() =>
							this.props.history.push("/pages/SupportFiscalReportDarkMode")
						}
						></Button>
					</div>
				)}
				</div>
			</View>

    );
  }
}

export default ({ history }) => (
  <View style={darkTheme.container}>
    <Login history={history} />
  </View>
);

//************************************************************************************************
// Main Stop
//************************************************************************************************

const darkTheme = {
  container: {
    flex: 1,
    alignItems: "strech",
    justifyContent: "center",
    padding: 20,
    fontWeight: 600,
    body: "#363537",
    text: "#FAFAFA",
    toggleBorder: "#6B8096",
    background: "#999",
  },
};
/*
		  
*/
