//************************************************************************************************
//es6 modules
//************************************************************************************************

import React, { useEffect, useState } from 'react';
import { ActivityIndicator, FlatList, Button, Image, StyleSheet, Text, View } from 'react-native';
import ReactDOM from 'react-dom';
import ReactDataGrid from 'react-data-grid';
import { Toolbar, Data, Filters } from "react-data-grid-addons";
//import faker from "faker";
import PropTypes from 'prop-types';
import axios from "axios";
import { CSVLink, CSVDownload } from "react-csv";
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import "../assets/custom_styles.css";
import globalVars from "./globalVars.js"

//************************************************************************************************
// Javascript Start
//************************************************************************************************

//************************//
// Global Variables Start //
//************************//

var StartDateMS = Date.now() - 2*604800000;
var StopDateMS = Date.now()

var StartDate = new Date(StartDateMS).toISOString().split('T')[0]
var StopDate = new Date(StopDateMS).toISOString().split('T')[0]

var filteredRows
var transactionCount
var amountTotal
var tbl = []

const faker = require('faker');


//***********************//
// Global Variables Stop //
//***********************//

const selectors = Data.Selectors;

const {
	NumericFilter,
	AutoCompleteFilter,
	MultiSelectFilter,
	SingleSelectFilter
} = Filters;

const defaultColumnProperties = {
	filterable: true,
	width: 325,
	editable: true

};

const amountFormatter = ({ value }) => {
	return <div>${value}</div>;
};

const columns = [
	{ key: 'txnID',					name: 'Load ID'	},
	{ key: 'date_sale',	 			name: 'Sale Date'	 },
	{ key: 'date_deposit',	 		name: 'Deposit Date'	 },
	{ key: 'type',		 			name: 'Host'	 },
	{ key: 'amount_net',	 		name: 'Amount Net',					filterRenderer: NumericFilter, formatter: amountFormatter },	
	{ key: 'amount_gross',	 		name: 'Amount Gross',				filterRenderer: NumericFilter, formatter: amountFormatter },	
	{ key: 'amount_fee',	 		name: 'Amount Fee',					filterRenderer: NumericFilter, formatter: amountFormatter },	
	{ key: 'subDept', 				name: 'Subdepartment' },		
//	{ key: 'site',		 			name: 'Subdepartment Number',	filterRenderer: NumericFilter },
	{ key: 'auth', 					name: 'Auth Number'},
	{ key: 'control_number', 		name: 'Control Number'},	

//	{ key: 'ftrans',	 name: 'PO #'	 },
	{ key: 'purchaseOrderNumber',	name: 'PO #'	},	

].map(c => ({ ...c, ...defaultColumnProperties }));

const hostEnum = [
	{ value: 1,    label: 'HOST_COMDATA'  },
	{ value: 2,    label: 'HOST_TCH'      },
	{ value: 4,    label: 'HOST_EFS'      },
	{ value: 8,    label: 'HOST_TCHECK'   },
	{ value: 16,   label: 'HOST_WORLDPAY' },
	{ value: 32,   label: 'HOST_RELAY'    },
]

const hostFormatter = ( value ) => {
	var x = "No Valid Host Found"; 
	for(var i = 0; i < hostEnum.length; i++) { 
		if(value == hostEnum[i].value) { 
			x = hostEnum[i].label
		}
	} 

	return x;
};

const handleFilterChange = filter => filters => {
	const newFilters = { ...filters };
	if (filter.filterTerm) {
		newFilters[filter.column.key] = filter;
	} else {
		delete newFilters[filter.column.key];
	}
	return newFilters;
};

function getValidFilterValues(rows, columnId) {
	return rows
		.map(r => r[columnId])
		.filter((item, i, a) => {
			return i === a.indexOf(item);
		});
}

function getRows(rows, filters) {
	return selectors.getRows({ rows, filters });
}

function createRow(r) {
//	var date = new Date(r.Date); 
//	var s = date.toISOString().split('T')[0];
	console.log(r)
	return {
		status: r.Status,
		type: hostFormatter(r.pay_Host),
		amount_fee: (r.Amount_fee / 100),
		amount_gross: (r.Amount_gross / 100),
		amount_net: (r.Amount_net / 100),		
		location: r.location,
		site: r.Site,
		ftrans: r.Ftrans,
		purchaseOrderNumber: parseNumbers(r.po_numbers),
		guid: r.GUID,
		date_sale: r.Date_sale,
		date_deposit: r.Date_deposit, 
		auth: r.Auth_num,
		control_number: r.Control_number,
		txnID: r.txnID,
		subDept: r.Subdep_num

	};
}

function parseNumbers(r) { 
	if(r !== undefined) { 
		var dat = ""
		for(let i = 0; i < r.length; i++) { 
			dat += r[i] + " "
		}
		return dat; 
	} else { 
		return "No Po Numbers"
	}

}

function formatTable(t,s,e) { 
	var ft = []

	for (let i = 0; i < t.length; i++) { 
//		if(s < t[i].Date && t[i].Date < e) { 
			ft.push(createRow(t[i]))
//		}
	}

	return ft
}

function createFakeRow() {
//	console.log(faker.date.recent().toString())
	var x =	hostEnum.sort(() => (Math.random() > .5) ? 1 : -1);

	return {
		type: x[0].label,
		amount: faker.finance.amount(),
		location: faker.address.city(),
		site: 350,
		ftrans: "test",
		purchaseOrderNumber: 66350,
		guid: "test",
		auth: Math.round(faker.finance.amount()*100),
		date: faker.date.recent().toString(),
	};
}

function formatFakeTable() { 
	var ft = []; 
	for (let i = 0; i < 100; i++) { 
		ft.push(createFakeRow())
	} 	
	return ft; 
}

//************************************************
// Axios Stuff Start
//************************************************

function sortFunction(a, b) {
    if (a.txnID === b.txnID) {
        return 0;
    }
    else {
        return (a.txnID > b.txnID) ? -1 : 1;
    }
}

//*****************\\
// Display On Load \\
//*****************\\

function DisplayBankDataOnLoad() {

	const [rows, setRows] = useState([]);
	const [value, onChange] = useState(new Date());	
	const [filters, setFilters] = useState({});

	if(tbl.length == 0 ) { 
		console.log('GET Request');
		const res = axios({
			method: 'get', 
			url: 'https://' + globalVars.OldAuthProvider + ':' + globalVars.OldAuthProvider + '/GetParsedDepositData'
		})
		.then(res => {

			tbl = (res.data).sort(sortFunction)

			console.log(res)
			setRows(formatTable(tbl,StartDateMS,StopDateMS)) // Take recieved data and create a table which the ReactDataGrid can use. 
		})
		.catch(err => console.error(err));
	} 

/*
	if(rows && rows.length > 0) { 

	} else { 
		console.log("Setting Rows")
		setRows(formatFakeTable())
	}
*/


	filteredRows = getRows(rows, filters);

	if(filters === {}) { 
		transactionCount = rows.length
	} else { 
		amountTotal = 0
		transactionCount = filteredRows.length
		if(filteredRows[0] !== undefined) { 
			for (let i = 0; i < filteredRows.length; i++) { 
				amountTotal += filteredRows[i].amount
			}
		}
	}

	const calChange = e => { 
		onChange(e)
		StartDate = e[0].toISOString().split('T')[0];
		StopDate = e[1].toISOString().split('T')[0];
		StartDateMS = Date.parse(StartDate)
		StopDateMS = Date.parse(StopDate)
		setRows(formatTable(tbl,StartDateMS,StopDateMS)) 
	}		

	const changeStart = e => {
		StartDate = e.target.value
		StartDateMS = Date.parse(StartDate)
		if( isNaN(StartDateMS)) { 
			StartDateMS = Date.now() - 2*604800000;
		}
		setRows(formatTable(tbl,StartDateMS,StopDateMS))
	};

	const changeStop = e => {
		StopDate = e.target.value
		StopDateMS = Date.parse(StopDate)
		if(isNaN(StopDateMS)) { 
			StopDateMS = Date.now()
		}
		setRows(formatTable(tbl,StartDateMS,StopDateMS))
	};

	return (
		<View>	

			<View>
				<Calendar 
					onChange={calChange}
					value={value}
					selectRange={true}
				/>
			</View>

			<View style={styles.container}>
				<form>
					<label>From Date:</label>
					<input type="text" name='FromDate' onChange={changeStart}/>
					<label>To Date: </label>
					<input type="text" name='ToDate' onChange={changeStop}/>
				</form>
			</View>
			<View>
				<div className="custom-style">
					<ReactDataGrid
						columns={columns}
						rowGetter={i => filteredRows[i]}
						rowsCount={filteredRows.length}
						width={250}
						toolbar={<Toolbar enableFilter={true} />}
						onAddFilter={filter => setFilters(handleFilterChange(filter))}
						onClearFilters={() => setFilters({})}
						getValidFilterValues={columnKey => getValidFilterValues(rows, columnKey)}
						enableCellSelect={true}
					/>
				</div>
			</View>
		</View>
	);
}

//************************************************
// Axios Stuff Stop
//************************************************

//************************************************************************************************
// Javascript Stop
//************************************************************************************************

//************************************************************************************************
// Main Start
//************************************************************************************************

export default ({history}) => (

	<View>
		<View> <h3> Host Transaction Report </h3> </View>
		<Button title="Fiscal Report" onPress={() => {
			resetData()
			history.push("/pages/FiscalReport")}
		}></Button>
		<Button title="Host Transaction Report" onPress={() => {
			resetData()	 
			history.push("/pages/BankReport")}
		}></Button>
		<Button title="Host Deposit Report" onPress={() => {
			resetData()
			history.push("/pages/BankDepositReport")}
		}></Button>
		<Button title="Gap Report"	onPress={() => {
			resetData()
			history.push("/pages/GapReport")}
		}></Button>
		<Button title="Gap Deposit Report"	onPress={() => {
			resetData()
			history.push("/pages/GapDepositReport")}
		}></Button>					 
		<Button title="Support" onPress={() => {
			resetData()
			history.push("/pages/SupportFiscalReport")}
		}></Button>				
		<View> {DisplayBankDataOnLoad()} </View>
		<View>Displayed Transaction Total: {transactionCount}</View>
		<CSVLink data={filteredRows}>Download Bank Data</CSVLink>
	</View>

);

//************************************************************************************************
// Main Stop
//************************************************************************************************

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#fff',
		alignItems: 'strech',
		justifyContent: 'center',
		padding: 20
	},
});

function resetData() { 
	StartDateMS = Math.round((Date.now() - 2*604800000)/1000);
	StopDateMS = Math.round(Date.now())
	StartDate = new Date(StartDateMS).toISOString().split('T')[0]
	StopDate = new Date(StopDateMS).toISOString().split('T')[0]
	tbl = [];
}